.topic-configuration .channel-field-wrapper {
	display: flex;
	align-items: center;
}
.topic-configuration .channel-field-selector {
	width: 100%;
}
.topic-configuration .channel-field-private-mark-wrapper {
	margin-right: 5px;
}

.topic-configuration .channel-field-private-text-wrapper {
	margin-top: 5px;
}
.topic-configuration .channel-field-private-text-wrapper > label {
	margin-bottom: 0;
}
