
.feed-options-bar { flex: 2 1; line-height: 2.8rem; }
.feed-options-bar .btn { 
	margin-left: 4px; 
	margin-right: 4px; 
}

.feed-options-bar .btn.btn-outline { 
	border-color: #ebebeb;
	color: #ebebeb;
}

.feed-options-bar label { margin-bottom: 0; }