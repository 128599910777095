.switch label {
    text-transform: uppercase;
    vertical-align: top;
}
.toggledLabel {
    font-weight: 700;
}
.untoggledLabel {
    font-weight: normal;
}

.switchToggle {
    margin-top: 1px;
}
.switchToggle span,
.switchToggle span:before,
.switchToggle input:checked + span,
.switchToggle input:checked + span:before {
    border-color: #333333;
}

.disabled label {
    color: #aaa;
}

.disabled .switchToggle span,
.disabled .switchToggle span:before,
.disabled .switchToggle input:checked + span,
.disabled .switchToggle input:checked + span:before {
    border-color: #aaa;
}
.switchToggleInverse span,
.switchToggleInverse span:before,
.switchToggleInverse input:checked + span,
.switchToggleInverse input:checked + span:before {
    border-color: #ffffff;
}
.disabled .switchToggleInverse span,
.disabled .switchToggleInverse span:before,
.disabled .switchToggleInverse input:checked + span,
.disabled .switchToggleInverse input:checked + span:before {
    border-color: #eeeeee;
}
.inverse label {
    color: #ffffff;
}
.disabled.inverse label {
    color: #eeeeee;
}
