.pop-over-container { display: inline-block; }

.pop-over {
    display: none;
    opacity: 0;
    position: absolute;
    z-index: 3;
    background: #fff;
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.5);
    text-align: left;
}

.pop-over h4 { padding: 4px 12px 0 }

.pop-over-content { padding: 8px 12px; }

.pop-over.no-padding .pop-over-content { padding-left: 0; padding-right: 0; }

.pop-over-overlay .overlay {
	display: block;
	position: fixed;
	top: 0; right: 0; bottom: 0; left: 0;
	height: 100%; width: 100%;
	z-index: 1000;
	background-color: rgba(0, 0, 0, .0);
}

.pop-over-caret {
	height: 16px;
    width: 16px;
    position: absolute;
    background: #fff;
    transform: rotate(45deg);
}