.entity-details-page.in-edit::before {
	content: " ";
	background: rgba(0, 0, 0, 0.3);
	position: absolute;
	height: 100%; width: 100%;
	z-index: 3;
	top: 0;
}

.entity-details-page .panel { 
	margin-top: 16px;
	padding-left: 16px; padding-right: 16px; 
	position: relative; 
	height: auto;
}

.entity-details-page.in-edit .panel{
	margin-left: 8px; margin-right: 8px;	
}

.entity-details-page .panel.in-edit { z-index: 4; padding-top: 16px; padding-bottom: 16px; }

.entity-details-page .panel h5 { line-height: 2.6rem; }


.entity-details-page .btn-edit { position: absolute; right: 16px; }

.entity-details-page .users-grid,
.entity-details-page .entity-alerts { margin-top: 24px }

.entity-details-page .users-grid.in-edit > .users-grid { display: none; }