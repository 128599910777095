.stepContainer {
    border-bottom: 1px solid #e6e6e6;
    padding: 12px 16px;
    margin-left: -8px; margin-right: -8px;
    cursor: pointer; height:  52px;
}

.progressBar .steps-container > div:first-child {
    padding-top: 12px;
}

.progressBar .steps-container > div:last-child {
    border-bottom: none;
}
.stepIndex {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 2px solid #9d9d9d;
    color: #9d9d9d;
    text-align: center;
    padding: 1px;
    display: inline-block;
    font-size: 1.2rem;
}
.stepTitle {
    display: inline-block;
    padding: 5px 5px 0;
    color: #6c6c6c;
    text-transform: uppercase;
}

.stepTitle .warn-sign { display: none; color: #e9b34d; vertical-align: text-top; }

.stepDescription {
    color: #c0c0c0;
    font-weight: normal;
    font-size: 1.4rem;
    padding: 18px 0 15px 8px;
    display: none;
    background: #fff;
    position: absolute;
    left: -100%; width: 100%; margin-top: -39px;
    border: 1px solid #e6e6e6;
    border-right: none;
    text-align: center;
    box-shadow: -4px 2px 4px 0px rgba(0, 0, 0, 0.1);
    text-transform: uppercase;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px
}

.stepContainer:hover .stepDescription,
.stepTitle.active + .stepDescription { 
    display: block;
}

.stepContainer.active { height: auto; }
.stepTitle.active + .stepDescription { 
    position: relative; 
    padding-left: 28px;
    border: none;
    margin-top: 0; 
    left: auto;
    padding: 0px 0 4px 26px;
    text-align: left;
    box-shadow: none;
    font-size: 1.2rem;
}

.done { color: #1b94d1; }
.stepIndex.done { background-color: #1b94d1; border-color: #1b94d1; }
.stepIndex.done svg { color: #fff; vertical-align: middle; }

.has-warning .warn-sign { display: inline; }

.has-errors {
    color: #fa4e3e;
    border-color: #fa4e3e;
}

.not-started {
    color: #6c6c6c;
}

.required:after {
    content:"*";
    color: #fa4e3e;
    padding-left: 5px;
    font-size: 16px;
}

.active { color: #1b94d1; font-weight: bold; padding-bottom: 4px }

.stepIndex.active {
    border-color: #1b94d1;
}
