.no-data-overlay {
	background: #fff;
	opacity: 0.7; 
	position: absolute;
	height: calc(100% - 51px);
	bottom: 8px; left: 8px; right: 8px; top: 51px;

	text-align: center;
}

.no-data-overlay label {
	text-transform: uppercase;
	text-align: center;
	position: relative;
	top: 50%;
	margin-top: -4.2rem;
	opacity: 0.85;
}

.voyager-container .no-data-overlay {
	z-index: 1;
	top: 0; left: 0; right: 0; bottom: 0;
	opacity: 0.8
}