.autocomplete {
	height: 34px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    border-radius: 4px;
	border: 1px solid #ccc;
	padding: 8px 12px;
	background: #fff;
}

.autocomplete.disabled {
	background-color: #eee;
    opacity: 1;
}

.autocomplete.has-errors {
	border-color: #fa4e3e;
}

.autocomplete > .glyphicon-chevron-down { float: right; }

.autocomplete > label.selection {
	display: inline-block;
	margin-bottom: 0;
	width: calc(100% - 50px);
	overflow: hidden;
    height: 1.6rem;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.autocomplete label.selection.empty {
	color: #ccc;
}

.autocomplete-container {
	margin: 0 -13px 0; padding: 16px;
	z-index: 9999;
	position: absolute; 
	border-radius: 4px;
	border: 1px solid #ccc;
	border-top: none; border-top-left-radius: 0; border-top-right-radius: 0;
	background: #fff;
}

.autocomplete-dropdown-open {
	position: relative;
	max-height: 400px; margin-top: 16px;
	overflow-y: auto;
}

.autocomplete-options { 
	list-style: none;
	background: #fff; 
	margin: 0; padding: 0;
}

.autocomplete-option {
	font-size: 1.4rem;
	padding: 8px;
	cursor: default;
}

.autocomplete-option-highlighted {
	background: #e1f5fe;
}