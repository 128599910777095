.register-form { 
	max-width: 1280px; min-width: 768px; 
	margin: 0 auto 52px;
}

.register-form .card-content{ 
	width: 100%;
	display: flex !important;
}

.register-form .card-content > div {
	flex: 1 1;
	max-width: 50%;
	padding: 16px 32px;
}

.register-form .greeting { text-align: center; }
.register-form .greeting img { width: 100%; }

.register-form .user-edit-form { padding-left: 0; padding-right: 0 }
.register-form .user-edit-form .form-group { margin-left: 0; }


.register-form .btn-toolbar { line-height: 3.2rem; }
.register-form .btn-toolbar span {
    font-size: 1.8rem; text-transform: uppercase;
    margin: 4px 8px 0;
    vertical-align: middle
}

.register-form .btn-toolbar span a { font-weight: bold; }

.register-form .captcha-container { margin-bottom: 32px; }

@media (max-width: 769px) {
	.register-form {
		min-width: 100%; max-width: 100%; width: 100%;
	}

	.register-form .card-content {
		display: block !important;
	}

	.register-form .card-content > div {
		flex: none; max-width: 100%; width: 100%;
	}

	.register-form .greeting { display: block; }
	.register-form .user-edit-form { display: block; }
}