.calendar {
  width: 455px;
  margin: auto;
  position: absolute;
  z-index: 9999;
  background: #fff;
  /* padding: 8px; */
  box-shadow: 0px 0px 50px rgba(0,0,0,0.5);
  left: auto; right: auto;
  left: calc(50% - 222px)
}
.calendar .calendar__range {
  color: #5d5d5d;
  margin-bottom: 10px;
  padding: 8px 16px 0;
}

.calendar .close-date-range {
  position: absolute;
}

.calendar .calendar__range .calendar__from-date {
  min-width: 170px;
  vertical-align: top;
  min-height: 60px;
}
.calendar .calendar__range .calendar__from-date,
.calendar .calendar__range .calendar__image-arrow {
  display: inline-block;
}
.calendar .calendar__range .calendar__image-arrow {
  font-size: 2.5rem;
  color: #dbd7db;
  margin-right: 30px;
  vertical-align: middle;
}
.calendar .calendar__range .calendar__range-h {
  font-size: 1.2rem;
  text-transform: uppercase;
}
.calendar .calendar__range .calendar__range-month {
  font-size: 1.5rem;
}
.calendar .calendar__range .calendar__range-date {
  font-size: 2.3rem;
  line-height: 2.3rem;
  font-weight: 100;
  margin: 0 5px 0 -5px;
}
.calendar .calendar__range .calendar__range-day {
  font-size: 0.9rem;
  color: #a2a2a2;
  font-weight: 100;
}
.calendar .calendar__range td {
  padding: 0;
  line-height: 1.6rem;
}
.calendar .calendar__chooser {
  background: #fffdff;
  text-align: center;
}
.calendar .calendar__header {
  text-align: center;
  width: 455px;
  padding-top: 13px;
  border-top: 1px solid #efefef;
  margin-bottom: 4px;
}
.calendar .calendar__header .calendar__month-chooser {
  color: #5d5d5d;
  font-size: 1.5rem;
  margin-bottom: 16px;
  width: 455px;
  padding: 0 30px;
}
.calendar .calendar__header .calendar__month-chooser .calendar__prev-month {
  float: left;
}
.calendar .calendar__header .calendar__month-chooser .calendar__next-month {
  float: right;
}
.calendar .calendar__header .calendar__month-chooser .calendar__prev-month,
.calendar .calendar__header .calendar__month-chooser .calendar__next-month {
  cursor: pointer;
  color: #a2a2a2;
  font-size: 1.4rem;
  line-height: 1.4rem;
}
.calendar .calendar__days-names {
  color: #a2a2a2;
  font-size: 0.8rem;
}
.calendar .calendar__days-names .calendar__day-name {
  min-width: 65px;
}
.calendar .calendar__wrap {
  max-height: 301px;
  overflow: hidden;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}
.calendar .calendar__table {
  transition: all 0.3s ease-in;
  cursor: pointer;
  will-change: transform;
}
.calendar .calendar__table.animate--forward {
  animation: list-forward 0.3s forwards;
}
.calendar .calendar__table.animate--backward {
  animation: list-backward 0.3s forwards;
}
.calendar .calendar__table .calendar__day {
  z-index: -2;
  min-width: 65px;
  padding: 0;
  font-size: 1.4rem;
  text-align: center;
  color: #5d5d5d;
  border-bottom: 1px solid #fff;
}
.calendar .calendar__table .calendar__day:hover .calendar__inner-day {
  border-radius: 100%;
  border: 2px solid #349aef;
}
.calendar .calendar__table .calendar__day.dis {
  background: #fcf9fc;
  border-bottom: 1px solid #fcf9fc;
}
.calendar .calendar__table .calendar__day.sel {
  background: #349aef;
  color: #fff;
}
.calendar .calendar__table .calendar__day.sel--start {
  color: #fff;
}
.calendar .calendar__table .calendar__day.sel--start .calendar__inner-day {
  border-radius: 49px;
  border: 2px solid #349aef;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background: #349aef;
  margin-right: 0;
}

.calendar .calendar__table .calendar__day.sel--end .calendar__inner-day {
  border-radius: 0 50% 50% 0;
  border: 2px solid #349aef;
  background: #fff;
  margin-left: 0;
}
.calendar .calendar__table .calendar__day.out--range {
  color: #e3e3e3;
}
.calendar .calendar__table .calendar__day .calendar__inner-day {
  height: 49px;
  width: 49px;
  line-height: 45px;
  border-radius: 100%;
  border: 2px solid transparent;
  margin: auto;
}
@-moz-keyframes list-forward {
  0% {
    transform: translate3d(0px, 0px, 0px);
  }
  100% {
    transform: translate3d(0px, -200px, 0px);
  }
}
@-webkit-keyframes list-forward {
  0% {
    transform: translate3d(0px, 0px, 0px);
  }
  100% {
    transform: translate3d(0px, -200px, 0px);
  }
}
@-o-keyframes list-forward {
  0% {
    transform: translate3d(0px, 0px, 0px);
  }
  100% {
    transform: translate3d(0px, -200px, 0px);
  }
}
@keyframes list-forward {
  0% {
    transform: translate3d(0px, 0px, 0px);
  }
  100% {
    transform: translate3d(0px, -200px, 0px);
  }
}
@-moz-keyframes list-backward {
  0% {
    transform: translate3d(0px, -200px, 0px);
  }
  100% {
    transform: translate3d(0px, 0px, 0px);
  }
}
@-webkit-keyframes list-backward {
  0% {
    transform: translate3d(0px, -200px, 0px);
  }
  100% {
    transform: translate3d(0px, 0px, 0px);
  }
}
@-o-keyframes list-backward {
  0% {
    transform: translate3d(0px, -200px, 0px);
  }
  100% {
    transform: translate3d(0px, 0px, 0px);
  }
}
@keyframes list-backward {
  0% {
    transform: translate3d(0px, -200px, 0px);
  }
  100% {
    transform: translate3d(0px, 0px, 0px);
  }
}
