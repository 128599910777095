.side-panel-container {
    top: 70px; right: 2px; bottom: 32px;
    position: fixed;
    width: 60%; z-index: 3;
}

.main .content.live-feed-epxanded .side-panel-container {
    top: 120px;
}

.side-panel {
    position: relative; height: 100%;
    background: #fff;
    box-shadow: 0px 20px 20px 0px rgba(0,0,0,0.2);
}

.side-panel .page-heading {
    border-bottom: 1px solid #ddd;
    line-height: 8.4rem;
    margin: 0;
    padding: 0 16px;
}

.side-panel .page-heading .btn-toolbar { line-height: 8.4rem; }

.side-panel .side-panel-content {
    height: calc(100% - 8.4rem); overflow: auto;
}

.side-panel .side-panel-content.btn-toolbar-visible {
    height: calc(100% - 13.2rem); overflow: auto;
    
}

.side-panel .btn-toolbar.footer-toolbar { 
    height: 4.2rem; line-height: 4.2rem; padding:0 8px;
    border-top: 1px solid #ddd;
}

.side-panel .btn-toolbar.footer-toolbar .btn {
    vertical-align: middle;
}

@media (max-width: 769px) {

    .side-panel-container {
        top: 0px;
        width: 100%;
    }

}