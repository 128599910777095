.planNotAvailable {
	position: absolute;
	white-space: nowrap;
	font-size: 1.1rem;
	z-index: 2;
	right: 0;
	min-width: 100%;
	text-align: right;
}

.planNotAvailable.small {
	font-size: 0.95rem;
}

button .planNotAvailable {
	top: -4px; right: -4px;
}

.planNotAvailable.left {
	right: auto;
	left: 0;
	text-align: left;
}

.planNotAvailable.panel {
	right: auto;
	left: auto;
	text-align: center;
	position: relative;
}

.planNotAvailable label {
	background: #31708f;
	border: 1px solid #31708f;
	border-radius: 2px;
	padding: 4px 12px 3px 14px;
	display: none;
	line-height: 1.1rem;
	color: #fff;
	text-transform: uppercase;
	white-space: nowrap;
	width: auto;
}
.planNotAvailable.small label {
	line-height: 1rem;
}
.planNotAvailable.panel.small label {
	margin-top: 22px;
}

.planNotAvailable label span {
	position: relative;
	white-space: nowrap;
	display: block;
}

.planNotAvailable:hover label {
	display: inline-block;
}

.planNotAvailable.panel label {
	display: inline-block;
	margin-top: 28px;
}

.nonFreeAccountIcon {
	background: #fff;
	width: 20px; height: 20px;
	border-radius: 50%; padding-top: 2px;
	border: 1px solid #31708f;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 1;
	text-align: center;
	line-height: 12px;
}

.planNotAvailable.small .nonFreeAccountIcon {
	width: 18px; height: 18px;
}

.planNotAvailable.left .nonFreeAccountIcon {
	right: auto;
	left: 0;
}

.planNotAvailable.panel .nonFreeAccountIcon {
	right: auto;
	left: 50%;
	margin-left: -10px;
}

.nonFreeAccountIcon svg {
	margin: 0;
}

.planNotAvailable label { margin-left: 12px; margin-right: 12px; right: 0; z-index: 0; }