.toggle {
    position: relative;
    display: inline-block;
    width: 25px;
    height: 15px;
    margin: auto;
}

.toggle input {
    display: none;
}
input:checked + .slider {
    border: 2px solid #2196f3;
}
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid #5b6064;
    transition: 0.4s;
    border-radius: 15px;
}
input:checked + .slider:before {
    transform: translateX(10px);
    border: 2px solid #2196f3;
}
.slider:before {
    position: absolute;
    content: '';
    height: 9px;
    width: 9px;
    left: 1px;
    bottom: 1px;
    border: 2px solid #5b6064;
    transition: 0.4s;
    border-radius: 50%;
}

.disabled {
    background-color: #ddd;
    cursor: not-allowed;
}
.disabled,
.disabled:before,
.slider.inverse,
.slider.inverse:before
{
    border-color: #aaa;
}
input:checked + .slider.inverse,
input:checked + .slider.inverse:before {
    border-color: #fff;
}
input:checked + .slider.disabled,
input:checked + .slider.disabled:before {
    border-color: #337ab7;
}
.slider.disabled.inverse,
.slider.disabled.inverse:before {
    border-color: #eeeeee;
}
input:checked + .slider.disabled.inverse,
input:checked + .slider.disabled.inverse:before {
    border-color: #eeeeee;
}