.overall-micro-stats .stats-card { text-align: left; }
.overall-micro-stats .stats-card.empty .card-content { padding-top: 16px; }

.overall-micro-stats .stats-card > label {
	margin-bottom: 5px; margin-left: 4px;
    display: block;
}

.overall-micro-stats .stats-card label > a {
    display: inline-block; 
    color: inherit; 
    margin-left: 8px;
    max-width: calc(100% - 70px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: middle;
}

.overall-micro-stats .stats-card:hover label > a { color: #337ab7; }

.overall-micro-stats .stats-card label > a:hover { text-decoration: underline; }

@media (max-width: 1367px) and (min-width: 769px) {
    .overall-micro-stats .stats-col.always-horizontal .stats-card .stats-info .numbers { margin: 28px 0; display: inline-block; }
    .overall-micro-stats .stats-card { height: 125px; }
}