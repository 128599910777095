.topic-details-feed { 
	background: #fff; 
	margin-left: -8px; margin-right: -8px; 
	padding-left: 8px; padding-right: 8px;
}

.topic-details-feed .subpages-nav {
	padding-top: 12px;
    background: #ebebeb;
    height: 6.8rem;
    margin: 8px -8px;
    box-shadow: none;
	border-top: 1px solid #cccccc;
	display: flex;
}

.topic-details-feed .subpages-nav h5 { width: 272px; line-height: 5.6rem; margin: 0 0 0 16px; }

.topic-details-feed .subpages-nav .tab {
	background: none;
}

.topic-details-feed .subpages-nav .tab.active {
	box-shadow: 0 -2px 2px 1px rgba(0, 0, 0, .03);
	background: #fff;	
}

.topic-details-feed .search-filter-panel { margin-bottom: 16px; }
.topic-details-feed .search-filter-panel input[type="text"] { background: none; }

.topic-details-feed .feed-actions {
	display: flex;
    justify-content: center;
    align-items: center;
}

.topic-details-feed .group-item .has-attached-image p {
	display: inline-block; 
	width: calc(100% - 130px);
	overflow: hidden; 
	text-overflow: ellipsis;
}

.topic-details-feed .group-item .has-attached-image .pop-over-container { display: inline-block; vertical-align: top; }
.topic-details-feed .group-item .answer-attachment-preview { height: 100%; width: 100%; }

.topic-details-feed .group-item .answer-attachment-preview img {
	max-height: 100%; max-width: 100%;
}
.topic-details-feed .group-item.empty-qna { padding-left: 16px; }

.topic-details-feed .search-text-highlight { display: inline-block; background: rgb(255, 232, 157); padding: 2px; }

/* ------ GROUP BY VOTER ------ */
.grouped-answers.filters-open { min-height: 780px; }
.grouped-answers .group-item:first-child { border-top-width: 4px; }
.grouped-answers .group-item:last-child { border-bottom-width: 4px; }

.grouped-answers .group-item { 
	border-radius: 4px;
	background-color: #fafafa;
	margin-bottom: 2px;
	padding: 4px 16px 4px 0;
}

.group-item .expand-collapse { display: inline-block; margin-right: 4px; }

.group-by-question-container { padding-bottom: 16px; }

.group-by-question-container .group-head-item { 
	padding-right: 8px; 
	display: flex; 
	cursor: pointer; 
	background: #f5f5f5;
    height: 4.8rem;
    line-height: 4.8rem;
}

.group-by-question-container .group-head-item:hover { color: #337ab7 }

.group-by-question-container .group-head-item h4 { flex: 1 1; margin-top: 12px; margin-bottom: 12px; margin-left: 0; }

.group-by-question-container .group-head-item .meta { width: 512px; font-size: 1.2rem; vertical-align: middle; text-align: right }
.group-by-question-container .group-head-item:hover .meta { color: #333; }

.group-by-question-container.empty-answers .group-head-item { padding-left: 12px; padding-top: 0; padding-bottom: 0; } 

.group-by-question-container .group-question-item { padding-right: 8px; margin-bottom: 2px; background: #f5f5f5; display: flex; padding-top: 12px; padding-bottom: 0; padding-left: 18px }
.group-by-question-container .group-question-item .vote-timeline-item { width: 260px; border: none; padding-left: 0; }
.grouped-answers .group-head-item .group-head-left .vote-timeline-item { padding-left: 0; }

.group-by-question-container .group-question-item .question-item-answer-text { flex: 1 1; }

.group-by-question-container .group-question-item .meta { width: 60px; }

.group-by-vote-container { margin-bottom: 18px; }

.group-by-vote-container .group-head-item { display: flex; cursor: pointer; padding-top: 8px; }
.group-by-vote-container .group-head-item:hover { color: #337ab7 }

.group-by-vote-container .group-head-item .expand-collapse { margin-top: 8px; margin-bottom: 8px; }

.group-by-vote-container .group-head-item .meta { line-height: 3.4rem; flex: 1 1; width: 500px; vertical-align: middle; text-align: right; font-size: 1.2rem; }
.group-by-vote-container .group-head-item:hover .meta { color: #333; }
.group-by-vote-container .group-head-item .group-head-left { width: auto; border: none; }

.group-by-vote-container .group-vote-item { padding-top: 8px; padding-bottom: 8px; padding-left: 18px }

.group-by-vote-container .group-vote-item.deprecated, 

.group-by-vote-container .group-vote-item.deprecated svg { vertical-align: middle }

.group-by-vote-container .group-vote-item .vote-question-text { display: inline-block; width: calc(100% - 80px); }
.group-by-vote-container .group-question-item .meta { width: 80px; }

.group-by-vote-container .group-question-item .meta { width: 60px; }

div.group-by-question-list > p.info-text-qna {
	padding: 10px 12px 10px 12px;
}

.group-by-voter-container {
	background: #f1f1f1;
	padding: 8px; margin: 8px 0;
}

.group-by-voter-container .group-by-voter-item > h4 {
	margin: 4px 0;
	font-size: 1.4rem; line-height: 1.8rem; font-weight: normal;
}

.group-by-voter-container .group-by-voter-item > h4 > span {
	display: inline-block; margin-right: 4px; margin-left: 4px;
}

.group-by-voter-container .group-by-voter-item > h4 .opinion-increase {
	color: #00b522; font-weight: bold;
}

.group-by-voter-container .group-by-voter-item > h4 .opinion-decrease {
	color: #cf4d4d; font-weight: bold;
}

.group-by-voter-container .group-by-voter-item .group-by-vote-container .group-item {
	background: #fafafa; padding-right: 8px; margin-bottom: 4px;
}

.group-by-voter-container .group-by-voter-item .group-by-vote-container .group-item.group-head-item {
	margin-bottom: 0;
}

@media (min-width: 769px) {
	.group-by-vote-container .group-vote-item { display: flex; }

	.group-by-vote-container .group-vote-item .vote-question-text { flex: 1 1; }
	.group-by-vote-container .group-vote-item .vote-answer-text { flex: 1 1; }
}

@media (max-width: 769px) {
	.topic-details-feed .subpages-nav h5 { display: none; }

	.topic-details-feed .search-filter-panel { display: block; line-height: 4.8rem; }
	.topic-details-feed label.search-label { display: block; width: 100%; margin-left: 12px; }
	.topic-details-feed .search-filter-panel .form-inline{ flex: none; }

	.topic-details-feed .btn-filters {
		margin-left: 8px; margin-right: 8px; margin-bottom: 16px;
		width: calc(100% - 16px);
	}

	.topic-details-feed .btn-filters.active { 
		margin-left: 0px; margin-right: 0px; margin-bottom: 0;
		width: 100%; box-shadow: none; 
	}
	
	.topic-details-feed .feed-actions {
		display: block;
	}

	.topic-details-feed .feed-actions > .feed-options-bar { display: block; line-height: 2.4rem; flex: none; }
	.topic-details-feed .feed-actions > .feed-actions-buttons { white-space: nowrap; flex: none; display: block; max-width: 284px; padding-left: 8px; }
	.topic-details-feed .feed-actions .btngroup > button span { display: none }

	.topic-details-feed .filters-panel h4 { text-align: left }
	.topic-details-feed .filters-panel h5 { margin: 24px 4px 8px; display: block; width: auto; }

	.topic-details-feed .filters-panel .filter-options-group { display: block; width: auto; }
	.topic-details-feed .filters-panel .filter-option-toggle { width: 50%; }
	
	.grouped-answers .vote-timeline-item .timeline-item-info { 
		vertical-align: middle;
	}
	.grouped-answers .vote-timeline-item br { display: none; }

	.grouped-answers .group-head-item { display: block; }
	.grouped-answers .group-head-item .expand-collapse { display: inline-block; vertical-align: middle; padding-right: 0; }
	.grouped-answers .group-head-item .group-head-left { display: inline-block; vertical-align: middle; padding-right: 0; width: calc(100% - 36px) }

	.grouped-answers .group-head-item > .meta,
	.grouped-answers .group-head-item > .meta { width: 100%; text-align: left; margin-left: 12px; display: block; font-weight: 1.2rem }

	.grouped-answers .group-head-item.expanded > .meta p,
	.grouped-answers .group-head-item.expanded > .meta p {  text-overflow: ellipsis; overflow: hidden; white-space: nowrap }
	
	.group-by-question-container .group-head-item,
	.group-by-question-container.empty-answers .group-head-item { display: block; padding-top: 4px; padding-bottom: 4px; height: auto; }

	.group-by-question-container .group-head-item h4 { flex: none; }
	.group-by-question-container .group-head-item .meta { display: block; width: 100%; overflow: hidden; text-overflow: ellipsis; text-align: left; line-height: 1.6rem; margin-left: 8px; }

	.group-by-question-container .group-question-item { display: block; }
	.group-by-question-container .group-question-item .vote-timeline-item { width: 100%; display: block; }

	.group-by-question-container .group-question-item .question-item-answer-text { display: inline-block; width: calc(100% - 72px); }
	.group-by-question-container .group-question-item small.meta { display: inline-block; width: 72px; }

	.topic-details-feed .group-item .has-attached-image p {
		width: calc(100% - 50px);
	}
}