.user-details-page.in-edit::before {
	content: " ";
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    height: 100%; width: 100%;
	z-index: 3;
	top: 0;
}

.user-details-page .panel { 
	margin-top: 16px;
	padding-left: 16px; padding-right: 16px; 
	position: relative; 
	height: auto;
}

.user-details-page.in-edit .panel{
	margin-left: 8px; margin-right: 8px;	
}

.user-details-page .in-edit.panel { z-index: 4; padding-top: 16px; padding-bottom: 16px; }

.user-details-page .panel h5 { line-height: 2.6rem; }

.user-details-page .btn-edit { position: absolute; right: 16px; }

.user-details-page .user-entities-grid { margin-top: 24px }