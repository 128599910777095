.lifetime-statbox {
    white-space: nowrap;
    display: inline-block;
    margin-right: 8px;
}

.lifetime-statbox .stats-box {
    display: inline-block; 
    color: #666666; text-transform: uppercase;
    margin-left: 16px;
}

.lifetime-statbox hr { 
    display: inline-block; 
    width: 24px; margin: 0 8px;
    background: transparent; border-bottom: 1px dotted #bbbbbb;
    vertical-align: text-top;
}

.lifetime-statbox .stats-box:last-child,
.lifetime-statbox .stats-box:first-child {
    margin-left: 0px;
}

.lifetime-statbox hr + div.stats-box { margin-left: 0; }

.lifetime-statbox .stats-text,
.lifetime-statbox .stats-icon { display: inline-block; }

.lifetime-statbox .stats-icon {
    margin-right: 6px; margin-left: 6px;
}

.lifetime-statbox .stats-text strong, 
.lifetime-statbox .stats-text label {
    display: block;
    margin: 0;
}

.lifetime-statbox .stats-text label { font-size: 1.1rem; font-weight: 100; color: #bbbbbb; }
.lifetime-statbox .stats-text strong { font-size: 1.6rem; font-weight: 300; }


@media (max-width: 1567px) and (min-width: 1367px) {
    .lifetime-statbox strong {
        font-size: 12px;
    }
    .lifetime-statbox .first-vote,
    .lifetime-statbox .last-vote {
        min-width: 70px;
    }
    .lifetime-statbox small {
        font-size: 60%;
    }
}

@media (max-width: 1367px) and (min-width: 769px) {
    .lifetime-statbox strong {
        font-size: 11px;
    }
    .lifetime-statbox .first-vote,
    .lifetime-statbox .last-vote {
        min-width: 65px;
    }
    .lifetime-statbox small {
        font-size: 50%;
    }
}