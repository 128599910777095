.btn-upload {
    position: relative;
	overflow: hidden;
	cursor: pointer;
}

.btn-upload input[type="file"] {
	position: absolute;
	top: 0;
	height: 100%; width: 100%;
	opacity: 0;
	cursor: pointer;
}

.btn-upload span,
.btn-upload svg,
.btn-upload input { cursor: pointer }

.btn-upload[disabled] span,
.btn-upload[disabled] svg,
.btn-upload[disabled] input { cursor: not-allowed }

ul.uploaded-files-list { width: 100%; }

ul.uploaded-files-list li span.file-name {
	display: inline-block;
	width: calc(100% - 24px);
	overflow: hidden;
	text-overflow: ellipsis
}