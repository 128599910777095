.options-bar {
	font-weight: 300;
	margin-top: 12px;
	font-size: 1.5rem;
	display: block;
}

.stacked-horizontal-barchart .options-bar > label {
	padding: 4px 0px;
	margin: 0;
	font-weight: 300;
}

.option-sentiment-veryLow { color: #cc3a3a; }
.option-sentiment-low { color: #e35f77; }
.option-sentiment-medium { color: #5fa0e3; }
.option-sentiment-high { color: #32de5e; }
.option-sentiment-veryHigh { color: #4af86b; }

.option-gender-male { color: #3e6fa3; }
.option-gender-female { color: #a63ea4; }
.option-gender-other { color: #d7d7d7; }

.option-age-group-1 { color: #a6d948; }
.option-age-group-2 { color: #48d984; }
.option-age-group-3 { color: #48d9d4; }
.option-age-group-4 { color: #4895d9; }
.option-age-group-5 { color: #7348d9; }
.option-age-group-6 { color: #d7d7d7; }