.freeColors {
    background-image: linear-gradient(#ffffff, #e7e7e7);
}

.proColors {
    background-image: linear-gradient(#6CCBF0, #3B97C5); 
    color: #ffffff;   
}

.businessColors {
	background-image: linear-gradient(#4699C8, #3767C4);  
    color: #ffffff;  
}

.enterpriseColors {
	background-image: linear-gradient(#3861BD, #A738BF); 
    color: #ffffff; 
}

.freeTextColors {
    color: #bbb;
}

.proTextColors {
    color: #3B97C5;   
}

.businessTextColors {
    color: #3767C4;  
}

.enterpriseTextColors {
    color: #A738BF; 
}