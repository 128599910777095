.entities-list table tr.entity-inactive {
    color: #b7b7b7;
}

.entities-list .subscription.flex .subscription-icon {
    margin: auto;
}

.entities-list .subscription.flex .subscription-data {
    margin: auto;
}

.entities-list .subscription.flex .label-active {
    color: #5cb85c;
}

.entities-list .subscription.flex .label-expired,
.entities-list .subscription.flex .label-pending {
    color: #898989;
}

.entities-list .subscription.flex .label-expiring {
    color: #d15e42;
}

.name-width {
    width: 180px;
}

.created-on-width {
    width: 55px;
}

.created-by-width {
    width: 70px;
}

.actions-width {
    width: 200px;
}

.alerts-width {
    width: 90px;
}

.active-width {
    width: 90px;
}

.tiers-width {
    width: 70px;
}

.subscription-width {
    width: 120px;
}

@media (max-width: 769px) {
    .name-width {
        width: 24%;
        word-break: break-word;
    }
    
    .actions-width {
        width: 16%;
    }

    .alerts-width {
        width: 10%;
        word-break: break-all;
    }

    .active-width {
        width: 10%;
        word-break: break-all;
    }

    .subscription-width {
        width: 20%;
        word-break: break-word;
    }
}