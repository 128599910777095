.accountDetailsPage { max-width: 1280px; margin-right: auto; margin-left: auto; }
.accountDetailsPage .flex > .card.organization-details { flex: 2 1; }
.accountDetailsPage .flex > .card.account-plan { flex: 1 1; }

.accountDetailsPage .flex > .card:first-child { margin-right: 0; }
.accountDetailsPage .flex > .card:last-child { margin-left: 0; }

.accountDetailsPage .btn-edit { position: absolute; right: 16px; }

.accountDetailsPage p { margin-left: 8px; }

.accountDetailsPage .organization-details .entity-details-page { margin-left: -8px; }
.accountDetailsPage .organization-details .entity-details-page.in-edit { padding-top: 8px; }
.accountDetailsPage .organization-details .entity-details-page::before { top: 0px; }
.accountDetailsPage .organization-details .entity-details-page .panel { margin-left: 8px; }

.accountDetailsPage .account-plan .card-content { padding-left: 8px; }
.accountDetailsPage .account-plan h3 { 
	margin: -16px 0px 24px; 
	text-transform: uppercase; font-weight: bolder; 
}

.accountDetailsPage .account-plan p { margin-left: 0px; margin-bottom: 4px; font-size: 1.6rem; white-space: nowrap; }
.accountDetailsPage .account-plan p .btn { margin-left: 8px; }

.accountDetailsPage .account-plan .features-overview { margin-top: 16px; margin-bottom: 16px;}

.accountDetailsPage .account-plan .btn-cancel { padding-left: 4px; text-decoration: underline; }

.invoice-list a span{
	text-decoration: underline;
}

.invoice-list .invoice-resolve-action a:focus,
.invoice-list .invoice-resolve-action a:hover {
	color: rgba(224, 172, 172, 0.986);
}

@media (max-width: 769px) {
	.accountDetailsPage .flex { display: block; }
	.accountDetailsPage > .flex > .card { display: block; margin-left: 0px; margin-right: 0px; }
}
