.slider-container {
    text-align: center;
    padding: 20px;
    width: 100%;
}
.duration-label, .duration-mini-label {
    line-height: 70px;
}
.duration-mini-label  {
    font-size: 12px;
    color: #9d9d9d;
}