.search-field { 
    width: calc(100% - 8px); margin-left: 4px; margin-right: 4px;
    border: 1px solid #ccc; border-radius: 8px;
    border-right: none; border-left: none; border-top: none;
}

.form-inline .search-field span.input-group-addon {
    border: none;
    background: none;
    padding: 8px; width: 48px;
}

.form-inline .search-field input.form-control {
    padding: 8px;
    border: none;
    box-shadow: none;
    font-size: 1.8rem; line-height: 2.4rem; text-transform: uppercase; font-family: inherit;
}

.form-inline .search-field.has-clear-button input.form-control { width: calc(100% - 80px); }
.form-inline .search-field.has-submit-button input.form-control { width: calc(100% - 80px); }
.form-inline .search-field.has-submit-button.has-clear-button input.form-control { width: calc(100% - 160px); }

.form-inline .search-field button.btn-xs { width: 72px; margin-top: 0.7rem; }