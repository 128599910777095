.ordered-form-fields  .ordered-form-fields-item .autocomplete-search-input {
	display: none;
}

.autosuggest-tags-selector { 
	min-height: 38px;
    height: auto;
	padding-left: 4px; padding-bottom: 0;
	position: relative; 
}

.autosuggest-tags-selector > .glyphicon { vertical-align: top; padding-top: 6px; }

.autosuggest-tags-selector .autocomplete-dropdown-open { margin-top: 0; }

.autosuggest-tags-selector label.selections { width: calc(100% - 16px); margin-bottom: 0; }

.autosuggest-tags-selector .tag-input-container { white-space: nowrap; }

.autosuggest-tags-selector .search-tag-input { 
	border: none; 
	margin-left: 8px;
	height: 24px;
	min-width: 256px;
}

.autosuggest-tags-selector .tag-input-container small { text-transform: lowercase; font-weight: 300; color: #a0a0a0 }
.autosuggest-tags-selector .tag-input-container small em { border-bottom: 1px dotted #aaa; }

.autosuggest-tags-selector .search-tag-input:focus { border: none; }
.autosuggest-tags-selector .search-tag-input:disabled { background: #fff; cursor: not-allowed; }

.selection-tag {
	text-transform: lowercase;
    border: 1px solid #a6a6a6;
    border-radius: 4px;
	padding: 4px 8px; margin-right: 4px; margin-bottom: 4px;
	background: #dedede;
	line-height: 1.4rem;
	display: inline-block;
}

.autosuggest-tags-selector .autocomplete-container { width: 100%; margin-left: -4px; }

@media (max-width: 769px) {
	.autosuggest-tags-selector .search-tag-input-tips { 
		display: block;
	}

	.autosuggest-tags-selector .search-tag-input { 
		display: block;
	}
}