.options-selector { 
	display: inline-block;
	margin: 0 8px;
}

.options-selector .selection-label {
	display: flex;
	background: #e7f8fd; 
	color: #337ab7; 
	cursor: pointer;
	padding: 4px 8px;
	margin: 0;
}
.options-selector .selection-label > button {
	border: 0;
	padding: 0;
	font-weight: normal;
}

.options-selector .options-dropdown { 
	visibility: hidden;
	opacity: 0;
	padding: 2px 0px;
	position: absolute; z-index: 9999;
	transition:visibility 0.2s ease-out, opacity 0.2s ease-out;
	background: #60a0d4;
	max-height: 300px;
	overflow: auto
}

.options-selector .options-dropdown.open { 
	visibility: visible; opacity: 1; overflow: auto; overflow-x: hidden;
}

.options-selector .options-dropdown li.search input[type="text"] {
	background: transparent;
    border: none;
    border-bottom: 1px solid #fff;
    width: calc(100% - 16px);
	margin: 8px; margin-left: -1.8rem;
	position: relative;
	color: #fff; padding-left: 2.2rem;
}

.options-selector .options-dropdown li.search svg { margin-left: 8px; position: relative; color: #fff; }

.options-selector .options-dropdown .option { 
	color: #ddd;
	padding: 2px 8px;
	cursor: pointer;
	position: relative;
}

.options-selector .options-dropdown .option:hover { background: #82b8e4 }

.options-selector .options-dropdown .option.selected {
	color: #fff; 
}

.options-selector .options-dropdown .option.disabled {
	cursor: not-allowed;
	background: #638cad;
	color: #afafaf;
}

.options-selector .options-dropdown .option .non-free-account-icon {
	position: absolute;
	top: -7px; right: -7px;
	background: #fff;
	width: 20px; height: 20px;
	border-radius: 50%; padding: 1px 0 0 2px;
	border: 1px solid #aaa
}

.options-selector-overlay {
	position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: 9998;
}