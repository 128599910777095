.overal-rating-bar { display: flex; }

.overal-rating-bar .rating { flex: 1; text-align: left; display: flex; align-items: center; }
.overal-rating-bar .rating .rating-badge { width: 40px; margin-right: 10px; }
.overal-rating-bar .rating .rating-label { flex: 1 }

.rating-bar .mini-chart { width: 100px; margin-left: 10px; }

.stats-card .flex .stats-chart { width: 50px; margin-right: 8px; }

.stats-card .flex .stats-info { flex: 1 1; color: #bbbbbb; text-transform: uppercase }
.stats-card .flex .stats-info .numbers { font-size: 2.4rem; display: block; line-height: 3.8rem; }
.stats-card .flex .stats-info .description { font-size: 1.1rem; display: block; line-height: 1.0rem; }
.stats-card .flex .stats-info .description label { color: #000; font-weight: normal; line-height: 1.4rem }