.contentEditPage .fileNameFieldSet {
	display: flex;
	flex-direction: row;
	margin-top: 10px;
}
.contentEditPage .fileNameFieldSet .labelContent {
	display: flex;
	align-items: center;
	justify-content: center;
}

.contentEditPage .fileNameFieldSet .labelContent > label {
	margin-bottom: 0px;
}

.contentEditPage .mde-container > div {
	height: 100%;
}
