
.imageDnD div{
    width: 60%;
    height: auto;
    display:inline-block;
    z-index: 1;
    position: relative;
    line-height: 25px;
    top: 50%;
    transform: translateY(-50%);
}

.imageDnD button{
    border: 1px solid #aeaead;
    border-radius: 3px;
    padding: 5px 10px;
    margin-left: 5px;
}

.imageDnD .icon {
    display: block;
    float: none;
    margin: 5px auto;
}

.imageDnD .clearButton {
    position: absolute;
    top: 0;
    right:0;
    z-index: 10;
}