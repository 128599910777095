.votes-timeline { margin: 0 -8px; }
.vote-timeline-item { padding: 12px; border-bottom: 1px solid #dcdcdc; max-width: 320px; }
.vote-timeline-item:first-child { padding-top: 0; }
.vote-timeline-item:last-child { border-bottom: none; }

.vote-timeline-item .timeline-item-dot,
.vote-timeline-item .timeline-item-info {
	display: inline-block;
    vertical-align: top;
    line-height: 1.8rem;
}

.vote-timeline-item .timeline-item-dot .rating-badge { width: auto; height: auto }

.vote-timeline-item .timeline-item-info {
	text-transform: uppercase;
    width: calc(100% - 32px);
	padding-left: 10px;
	line-height: 1.6rem
}

.vote-timeline-item strong {
	font-size: 1.8rem;
}

.vote-timeline-item .timeline-item-info small:not(.text-muted) {
	font-weight: bold;
}