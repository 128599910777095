.plans-container {
    display: flex; 
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 24px auto 24px;
}

.plans-container .plan-container .title-price-overview {
    height: 270px;
    text-align: center;
}

.plans-container .plan-container.free .title-price-overview,
.plans-container .plan-container.enterprise .title-price-overview  { height: 192px; }

.plans-container .plan-container .title-price-overview.no-price { height: 156px; }

.plans-container .plan-container .title-container,
.plans-container .plan-container .mainfeatures-overview {
    padding: 0 16px;
    text-align: left;
}

.plans-container .plan-container .title-price-overview .btn { margin-top: 16px; }

.plans-container .plan-container {
    /* as a child component */
    flex: 1;

    /* as a parent component */
    display: flex;
    flex-direction: column; 

    /* general */
    position: relative;

    align-self: flex-start;
    
    min-width: 280px;
    width: 25%;
    max-width: 350px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.plans-container .plan-container .details {
    background: #fff;
    color: #666;
    text-align: center;
}

.plans-container .plan-container.free,
.plans-container .plan-container.enterprise {
    margin-top: 32px
}

.plans-container .plan-container.pro {
    margin-top: 16px
}

.plans-container .plan-container.free .details {
    background: transparent;
}

.plans-container .plan-container.enterprise .details {
    background: transparent;
    color: #fff;
}

.plans-container .plan-container .title-price-overview .pricetag-number {
    font-size: 4.2rem;
    font-weight: 800;
}

.plans-container .plan-container .title-price-overview .pricetag-currency {
    position: relative;
    font-size: 2.2rem;
    bottom: 15px;
    margin-right: 3px;
}

.plans-container .plan-container .title-price-overview .fadeout {
    color: #fcfcfc79;
}

.plans-container .plan-container .title-price-overview .prices-label {
    display: block;
    margin-top: -4px
}

.plans-container .plan-container .title-price-overview .prices-label > label{
    text-align: right;
    width: 50%;
    padding-right: 12px;
}

.plans-container .plan-container .title-price-overview .prices-label > label:last-child {
    text-align: left;
    padding-left: 22px;
}

.plans-container .slider,
.plans-container .slider:before,
.plans-container input:checked + .slider,
.plans-container input:checked + .slider:before {
    border: 2px solid #ffffff;
}

.plans-container .plan-container ul {
    margin: 8px 12px 8px;
}

.plans-container .plan-container li {
    line-height: auto;
    margin-bottom: 8px;
}

.plans-container .plan-container .features-overview {
    position: relative;
    height: 534px;
    text-align: left;
}

.plans-container .plan-container .mainfeatures-overview {
    border-top: 1px solid #c2c2c2;
    width: 100%;
    margin-top: 16px;
}

.plans-container .plan-container .mainfeatures-overview h5{
    text-transform: none;
    font-size: 1.6rem;  
    color: #949494;
    font-weight: 350;
}

.plan-container.free .title-price-overview .price-container,  
.plan-container.pro .title-price-overview .price-container, 
.plan-container.business .title-price-overview .price-container { 
    text-align: center;
}

/* FREE plans */

/* PRO plans */
.plan-container.pro .features-overview,
.plan-container.pro .mainfeatures-overview {
    border-right: 1px solid #e7e7e7;
}

/* BUSINESS plans */


/* ENTERPRISE plans */
.plan-container.enterprise {
    font-size: 16px;
}

.plan-container.enterprise .features-overview {
    font-weight: 500;
}

.plan-container.enterprise .mainfeatures-overview h5{
    color: #ffffff;   
}

@media (max-width: 1366px) {
    
}

@media (max-width: 769px) {
    .plans-container {
        flex-direction: column-reverse;
        flex-wrap: wrap;
    }
}