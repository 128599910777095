.topics-overview-header { display: flex; margin-bottom: 16px; }

.topics-overview-header .page-heading { 
	flex: 1 1; 
	overflow: hidden; text-overflow: ellipsis; white-space: nowrap; 
	margin-right: 24px;
	margin-bottom: 0;
}

.topics-overview-header .lifetimestats-container {	max-width: 674px; margin-right: 8px; height: 38px; margin-top: 10px; }
.topics-overview-header .lifetimestats-container .btn { vertical-align: text-bottom };

.topics-overview.content-with-stats .stats-container { margin-bottom: 16px; }


@media (max-width: 769px) {
	.content-with-stats .topics-tree-container { width: 100% }
}