.slider-selector, .range-slider-selector { height: 40px; }

.rc-slider-rail { height: 3px; background-color: #ccc; }

.rc-slider-track { background-color: #3998c8; }

.rc-slider-handle {
    width: 27px;
    height: 27px;
    margin-top: -12px;
    margin-left: -14px;
    border: solid 4px #f5f5f5;
    background-color: #3998c8;
    box-shadow: none;
    -webkit-box-shadow: none;
}

.rc-slider-dot { border-color: #ccc; }
.rc-slider-dot-active { border-color: #3998c8; }

.rc-slider-tooltip-inner {
    background-color: transparent;
    box-shadow: none;
    -webkit-box-shadow: none;
    color: #3998c8;
    font-weight: bold;
}