.navbar-default { 
    background: #fff; height: 70px; 
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
    margin-bottom: 0;
    position: relative;
    z-index: 4;
}

.navbar .container { padding: 0; width: 100%; }
.navbar > .container > .navbar-header { width: 100%; }

.navbar > .container .navbar-brand {
    height: 40px; width: 40px;
    float: none; color: #528bff;
    margin: 15px 20px; padding: 0;
    display: block;
}

.navbar > .container .navbar-brand > svg {
    fill: currentColor;
}

.navbar-collapse { padding: 0; }

ul.navbar-nav > li.dropdown { min-width: 200px; border-right: 1px solid #e0e0e0; }
ul.navbar-nav > li.dropdown:hover { background: #ebebeb;box-shadow: 0 2px 2px rgba(0,0,0,.3) }
ul.navbar-nav > li.dropdown:hover ul.dropdown-menu { display: block; line-height: normal; } 

ul.dropdown-menu { 
    z-index: 9999;
	border-radius: 0;
    margin: -3px 0 0;
    border: none;
    width: 100%;
    text-transform: uppercase;
	background: #ebebeb;
	box-shadow: 0 2px 2px rgba(0,0,0,.175)
 } 

.dropdown-menu > li a,
.dropdown-menu > li button { 
    font-weight: bold; 
    padding: 8px 12px;
    display:block; 
    width: 100%; 
    text-align: left; 
    border-radius: 0; 
    white-space: nowrap;
    text-decoration: none;
}

.dropdown-menu > li button:hover,
.dropdown-menu > li a:hover { background: #f5f5f5; }
.dropdown-menu > li button:active { box-shadow: none; }
.dropdown-menu > li button:active:focus { outline: transparent ; }

ul.dropdown-menu li a svg,
ul.dropdown-menu li btn svg { margin-right: 8px; vertical-align: text-top; } 

ul.navbar-nav > li { padding: 0px; font-weight: bold; }

.navbar-default .navbar-nav > li a {
    color: inherit;
}

ul.navbar-nav > li > a,
ul.navbar-nav > li > div > div > a,
ul.navbar-nav > li > button, 
ul.navbar-nav > li > .dropdown-toggle { 
    padding: 0 15px; line-height: 70px; 
}

ul.navbar-nav > li > .btn-live-feed.active {
    background: #fcc9c1;
    border-radius: 0;
    color: #333;
}

ul.navbar-nav > li > .btn-live-feed span.new-votes-count {
    display: inline-block;
    width: auto;
    padding: 2px 4px;
    /* height: 16px; */
    background: #FF0000;
    color: #fff;
    position: absolute;
    top: 14px;
    line-height: normal;
    right: 8px;
    border-radius: 4px;
    font-size: 1.2rem;
}

ul.navbar-nav > li > a svg,
ul.navbar-nav > li > div a svg,
ul.navbar-nav > li > button svg { vertical-align: middle }

ul.navbar-nav > li > a .navbar-menu-item-tooltip,
ul.navbar-nav > li > div > div > a .navbar-menu-item-tooltip {
    position: absolute;
    display: none;
    padding: 8px; margin-left: -15px; margin-top: -1px;
    text-transform: uppercase; white-space: nowrap;
    background: #e0e0e0;
    box-shadow: 0 12px 18px rgba(0, 0, 0, 0.2);
}

.navbar-default .navbar-nav > li > a.active,
.navbar-default .navbar-nav > li > a:focus { background: #e0e0e0; border: none; outline: transparent; }

.navbar-default .navbar-nav > li > a.active,
.navbar-default .navbar-nav > li > div > div > a.active { padding-left: 20px; padding-right: 20px; }

ul.navbar-nav > li > a.active .navbar-menu-item-tooltip,
ul.navbar-nav > li > div > div > a.active .navbar-menu-item-tooltip { margin-left: -20px; }

.navbar-default .navbar-nav > li > a:hover { background-color: #e0e0e0; }

.navbar-default .navbar-nav > li > a:hover .navbar-menu-item-tooltip { display: block; }

ul.navbar-nav > li a span,
ul.navbar-nav > li > .dropdown-toggle span { vertical-align: middle; line-height: normal; margin-right: 8px; }

ul.navbar-nav > li a .avatar,
ul.navbar-nav > li > .dropdown-toggle .avatar {
	display: inline-block; 
    border-radius: 1.2rem; background-color: #999; 
}

ul.navbar-nav > li a .name,
ul.navbar-nav > li > .dropdown-toggle .name {
	display: inline-block;
    font-size: 1.6rem;
    max-width: 256px;
    min-width: 168px;
    overflow: hidden; text-overflow: ellipsis; white-space: nowrap;
    
} 

ul.navbar-nav > li a .name small,
ul.navbar-nav > li > .dropdown-toggle .name small { display: block; font-size: 1.2rem; color: #b3b3b3; font-weight: 300; }

/* custom cases css styles */
.entity-nav-item .dropdown-menu { min-width: 280px; }