.user-edit-profile .change-password-section {
	paddding: 8px 8px 0px;
}
.user-edit-profile .change-password-section > * {
	margin: 8px 8px 24px;
}

.user-edit-profile .change-password-section .change-pass-title {
	text-transform: uppercase;
	margin-bottom: 4px;
	display: inline-block;
	max-width: 100%;
	font-weight: 700;
}
