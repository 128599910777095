.button-bar-input .buttons-bar {
    padding-right: 10px;
    padding-bottom: 5px;
}

.button-bar-input .selected-button {
    color: #333;
}

.button-bar-input .unselected-button {
    color: #999;
}

.button-bar-input .custom-value-prefix{
    padding: 5px;
    padding-top: 7px;
}
