.modal {
	display: block;
}

.modal-overlay {
	display: block;
	position: fixed;
	top: 0; right: 0; bottom: 0; left: 0;
	height: 100%; width: 100%;
	z-index: 1000;
	background-color: rgba(0, 0, 0, .55);
}

.modal-container {
	display: block;
	position: fixed;
	top: 0; right: 0; bottom: 0; left: 0; 
	z-index: 10000;
	overflow: hidden;
	text-align: center;
	padding: 4px;
}

.modal-container:after {
	vertical-align: middle;
	display: inline-block;
	height: 100%;
	margin-left: -.05em;
	content: '';
}

.modal-content {
	position: relative;
	outline: 0;
	width: 100%;
	background: white;
	display: inline-flex;
	flex-direction: column;
	vertical-align: middle;
	box-sizing: border-box;
	max-width: 520px;
	max-height: 97vh;
	cursor: default;
	background-color: #f6f6f6;
	box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
	border-radius: 4px;
}

.modal-title { 
	text-align: left; 
}

.modal-title h1 {
	margin: 12px; 
	font-weight: 900; font-size: 1.8rem; text-transform: uppercase;
}

.modal__close { float: right; color: #333; margin: -2px -4px 0 0 }

.modal-body {
	font-size: 1.6rem;
	vertical-align: middle;
	overflow-x: hidden;
	overflow-y: auto;
}

.modal-footer {
	border: none;
	padding: 8px;
	box-shadow: 0 2px 0px 0px rgba(0, 0, 0, .30);
}

.modal-body.no-padding { padding: 0; }