.live-feed {
	height: 48px; width: 100%;
	background: linear-gradient(#fcc7bf, #fdd7d0); 
	overflow: hidden;
	position: relative;
	box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid #d6d6d6;
    border-right: 1px solid #d6d6d6;
	margin-right: 1px;
	position: relative;
	z-index: 1;
}

.live-feed .scrollable-votes-container { 
	overflow-y: hidden; overflow-x: auto; 
	height: calc(100% + 30px); width: 100%; box-sizing: content-box; 
	position: relative;
}
.live-feed .items-container { 
	overflow: hidden; 
	height: 100%; 
	box-sizing: content-box; 
	padding-right: 196px; 
	position: absolute;
	width: auto;
	white-space: nowrap;
}

.live-feed .vote-timeline-item {
	display: inline-block;
	overflow: hidden;
	vertical-align: top;
	padding: 0 8px;
	max-width: 256px;
	background: none;
	transition: all 0.2s ease-in;
}

.live-feed .vote-timeline-item.animate {
	background: #f9bcb5;
	padding-bottom: 8px;
}

.live-feed .vote-timeline-item .timeline-item-dot,
.live-feed .vote-timeline-item .timeline-item-info {
	margin-top: 8px;
}

.live-feed .vote-timeline-item small.topic-name { 
	white-space: nowrap; text-overflow: ellipsis; 
	width: 100%; overflow: hidden;
	display: block; 
}

.live-feed .vote-timeline-item small.age-gender,
.live-feed .vote-timeline-item small.location,
.live-feed .vote-timeline-item small.sources {
	display: none; 
}

.live-feed .right-controls,
.live-feed .left-controls {
	position: absolute; width: 196px; height: 48px;
	right: 0; top: 0; text-align: right; padding-right: 16px;
	background: linear-gradient(#fcc7bf, #fdd7d0);
	box-shadow: 0px 0px 20px 20px rgba(0, 0, 0, 0.2);
	white-space: nowrap;
}

.live-feed .left-controls {
	right: auto; left: 0; width: 36px
}

.live-feed .right-controls label {
	line-height: 48px; font-weight: 100; font-size: 2.2rem; text-transform: uppercase;
	display: inline-block; margin-left: 16px;
}

.live-feed .right-controls button,
.live-feed .left-controls button {
	border-right: 1px solid #bbb;
	border-radius: 0;
	height: 100%;
}

.live-feed .left-controls button { border: none; }