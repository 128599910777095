.mini-chart small {
	opacity: 0.6;
	display: block; margin-top: -4px; 
	line-height: 0px; font-size: 0.9rem; 
	text-transform: uppercase; text-align: center; 
}

.mini-chart .text-in-bar {
	display: block; margin-top: 15px;
}

.mini-chart .custom-label-title {
	font-size: 1.1rem;
	opacity: 0.6;
	text-transform: uppercase;
}
.mini-chart .custom-label-value {
	font-size: 1rem;
	text-transform: uppercase;
	font-weight: bold;
}