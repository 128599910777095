.user-edit-form .user-avatar-upload .control-label {
    position: relative;
    bottom: -22px;
}

.user-edit-form .user-avatar-upload .imageWrapper {
    min-width: auto;
}

.user-edit-form .user-avatar-container.fit-desktop-flex-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1 1;
}

.user-edit-form .user-avatar-container .user-avatar-upload.fit-desktop-flex-item {
    flex-basis: 33.3%;
}

.user-edit-form .user-avatar-container .user-main-data.fit-desktop-flex-item {
    flex-basis: 66.6%;
}