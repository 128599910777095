.topic-component-container {
    width: 100%;
    height: 100%;
    background-color: #f6f6f6;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
}

.basic-topic-details .private-field-containter {
    margin-bottom: 0;
}

.component-label {
    font-weight: bold;
}
.checkbox-label {
    font-size: 14px;
    text-transform: uppercase;
    padding-left: 10px;
}
.label-description {
    width: 100%;
    margin: 0;
    font-size: 13px;
    color: #b7b7b7;
}
.image-dnd-container,
.image-preview {
    padding: 20px 0;
    width: 300px;
    height: 230px;
    margin-right: 20px;
}
.images-container {
    display: flex;
    flex-flow: row wrap;
}

@media (max-width: 767.98px) {
    .single-line-inputs {
        flex-wrap: wrap;
    }
}

.single-line-inputs {
    display: flex;
    justify-content: space-between;
}

.single-line-inputs > div {
    width: 100%;
}