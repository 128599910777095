.card { 
	background-color: #fff; padding: 8px; margin: 8px; position: relative;
	border-radius: 2px; box-shadow: 0 2px 2px 1px rgba(0, 0, 0, .10); 
}

.card h4 { 
	text-transform: uppercase; font-size: 1.8rem; font-weight: bold;
	margin-bottom: 2.4rem; margin-left: 8px
}

.card.collapsed h4 { margin-bottom: 4px; }

.card h4 .btn { padding: 0; margin-left: 8px; }
.card h4 .btn svg { vertical-align: baseline; }

.card.dark {
	background-color: #666666;
	color: #ebebeb;
}

.card.pad { background-color: #f5f5f5; margin-left: 4px; margin-right: 4px; }

.card.pad .card.pad { background: #f9f9f9; }

.card.options { background-color: #e1eefb; padding-left: 12px; padding-right: 12px; }
.card.options .nl-form { background: transparent; }

.card.dark strong { color: #fff; }

.card.no-h-offset { margin-left: 0; margin-right: 0 }

.card.pad .card { margin-left: 0; margin-right: 0 }

.card-overlay {
	position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: rgba(255, 255, 255, 0.7);
}

.card-overlay p {
	position: relative;
	top: 50%;
	margin-top: -3.2rem;
}


@media (max-width: 769px) {
	.card { margin-left: 4px; margin-right: 4px; }
}