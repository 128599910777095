@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&subset=cyrillic-ext,greek-ext');

html { height: 100%; }
body {
    font-family: 'Roboto', sans-serif;
    background-color: #ebebeb;
    height: 100%;
}

h5 { text-transform: uppercase; font-size: 1.6rem; }
.subtitle { font-size: 1.4rem; color: #c0c0c0; display: block; margin-top: 8px; }

.flex { display: flex; flex-flow: row }

/* TYPOGRAPHY */

label svg { vertical-align: text-bottom; margin-left: 4px; margin-right: 4px; }

.text-label { text-transform: uppercase; }
.text-label svg { vertical-align: text-top }

.text-light { font-weight: 300; }
.text-regular { font-weight: 400; }
.text-medium { font-weight: 500; }
.text-bold { font-weight: 700; }
.text-black { font-weight: 900; }

.text-white { color: #fff; }
.text-blue { color: #337ab7; }

.group-item .bg-muted {	background: #f1f1f1; }

.page-heading { 
	font-weight: 900; font-size: 3.6rem;
    text-transform: uppercase;
    margin: 0 12px 16px; line-height: 60px; min-height: 60px;
}

h5.page-heading { font-size: 2.4rem; }

.page-heading .btn-xs { 
    display: inline-block; vertical-align: middle; 
    margin-left: 8px; margin-top: -3px;
}

.page-heading .btn-toolbar { display: inline-block; line-height: 5.8rem; }
.page-heading .btn-toolbar .btn { vertical-align: middle; }

.page-heading:after {
    content: '';
    display: block;
    clear: both;
}

/* ------------ */

#root { height: 100%; }

.btn-support {
    background: #898989; color: #fff;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
}

.alert svg { vertical-align: bottom; margin: 0 4px; }

.bg-dark {
    background-color: #666666;
	color: #ebebeb;
}

.sub-header {
    height: 5px;
    color: #606060;
    position: fixed;
    z-index: 999;
    left: 0; top: 0;
    padding: 0;
    width: 100%;
}

.notifications-wrapper {
    position: relative;
    z-index: 5;
}

.main { 
    left: 0;
    height: calc(100% - 36px); box-sizing: border-box;
    transition: left 0.1s ease-out;
    align-items:flex-start;
    align-content:flex-start; 
    overflow: hidden;
}

.main.open {
    left: 200px;
    transition: left 0.2s ease-in;
    overflow: visible;
}

.main .content {
    width: 100%;
    height: calc(100% - 70px); 
    overflow: auto;
    padding: 24px 16px 0; 
    position: relative;
}

.main .content.live-feed-epxanded { height: calc(100% - 120px); }

#menuToggle input {
  display: block;
  width: 40px; height: 32px;
  position: absolute; top: -7px; left: -5px;  
  cursor: pointer;
  
  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */
  
  -webkit-touch-callout: none;
}

.votes-timeline-container { 
    width: 320px; height: auto;
    margin: 54px 16px 0;
    background: #d7d7d7; 
}

.votes-timeline-container h3 { 
    padding-left: 16px; 
    margin-top: 0; margin-bottom: 0;
    text-transform: uppercase; line-height: 4.8rem; color: #fff; font-size: 1.8rem;
    background-color: #666;
} 

.votes-feed {
    padding-bottom: 54px;
    overflow: auto;
}

.votes-timeline-container .votes-timeline { margin-left: 24px; }

.overview {
    display: flex; flex: 1;
    margin: 0; 
}

.overview .topics-tree-container { 
    flex: 3; padding: 0;
}

.radial-bar-mobile { display: none; }
.radial-bar-desktop { display: block; }

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.table th {
  text-align: center
}

.list-container .grid-container .grid-container-filters input{
    margin: 5px 18px 5px 0;
}

.list-container .grid-container .grid-container-filters button{
    margin: 0 4px;
}

.list-container .grid-container .grid-container-filters label{
    margin-right: 8px;
}

.list-container .grid-container .user-management-grid-container .search-field .form-control {
    background: none;
}

.list-container .grid-container .user-management-grid-container .react-bs-table {
    margin: 8px;
    border-radius: 5px;
}

.list-container .grid-container .user-management-grid-container .react-bs-container-header {
    display: none;
}

.react-bs-table .table > tbody > tr > td { vertical-align: middle; background: #fafafa; border-top: 4px solid #fff; outline: transparent }
.react-bs-table .table > tbody > tr:hover > td { background: #fff; }

.react-bs-table .table > tbody > tr > td.action-cell { padding: 0; text-overflow: unset; overflow: unset }
.react-bs-table .table > tbody > tr > td.action-cell:hover { background: #87cdfe; }

.react-bs-table .table > tbody > tr > td.action-cell .btn { color: #000 }
.react-bs-table .table > tbody > tr > td.action-cell:hover .btn { color: #fff }

.radial-bar{
    padding-top:50px;
    padding-left:30px;
}

.graph-container-row{
    margin-top:50px;
}

.btn-toolbar.no-float .btn {
    float: none;
}

button, .btn:focus { outline: none; }

.btn { text-transform: uppercase; padding: 4px 8px; font-weight: bold; position: relative; }

.btn.btn-xs { font-size: 11px; padding: 4px; }

.btn svg { vertical-align: text-bottom; margin-right: 4px; }

.btn-outline-danger, .btn-outline.btn-danger { border-color: #d15e42; color: #d15e42; }
.btn-outline-primary, .btn-outline.btn-primary { border-color: #3797c4; color: #3797c4; }
.btn-outline-success, .btn-outline.btn-success { border-color: #5cb85c; color: #5cb85c; }
.btn-outline-white, .btn-outline.btn-white { border-color: #fff; color: #fff; }

.btn-outline.btn-white:hover, .btn-white:hover { background-color: #fff; color: #999; }

.btn-outline {
    border: 1px solid #999; color: #999;
    background: transparent;
    border-radius: 4px;
    text-align: center;
    padding: 4px 8px;
}

.btn-outline:hover { color: #999; }

.btn-full-width { display: block; width: 100%; }

.btn-dotted { border-style: dotted; }

.btn-float {
    position: fixed; z-index: 4;
    right: 8px; bottom: 48px;
    border: none;
    text-align: center;
    white-space: nowrap;
    padding: 0;
}

.btn-float .btn {
    width: 48px; height: 48px;
    border-radius: 24px;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
    padding: 0;
    text-align: center;
    line-height: 45px;
}
.btn-float.btn-float-second { bottom: 108px; }

.btn-float label {
    display: none;
    text-transform: uppercase;
    background: #fff;
    padding: 4px 8px;
    margin-right: 0;
}

.btn-float:hover label {
    display: inline-block;
}

.btn-float .btn svg { vertical-align: middle; margin: 0; }

.btn-group-footer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    align-content: space-around;
    padding: 0;
    box-sizing: inherit;
}

.btn.transparent, .btn.btn-transparent, .btn-transparent { border: none; background: none; }

.dropdown-button-container {
    border: 1px solid #999; color: #999;
    background: transparent;
    border-radius: 4px;
}

.dropdown-button {
    width: 100%;
}

.dropdown-list-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: flex-start;
}

.dropdown-list-item {
    border: 0;
}

.dropdown-list-item:hover {
    color: #333;
}

.message { padding: 8px; border-radius: 2px; }
.message svg { vertical-align: middle }

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.3);
	opacity: 0;
	z-index: 9998;
	visibility: hidden;
	-webkit-transition: visibility 0s 0.3s, opacity 0.3s;
	-moz-transition: visibility 0s 0.3s, opacity 0.3s;
	transition: visibility 0s 0.3s, opacity 0.3s;
}

.overlay {
	opacity: 1;
	visibility: visible;
	-webkit-transition-delay: 0s;
	-moz-transition-delay: 0s;
	transition-delay: 0s;
}

.leaflet-container {
    height: 400px;
    width: 100%;
    z-index: 1;
}
.leafet-top {
    z-index: 2;
}

.hidden-element { 
    opacity: 0; 
	width: 1px; 
	height: 1px;
	overflow: hidden;
}

.white-background-color { 
    background-color: #fff;
}

.transparent-background-color { 
    background-color: transparent;
}

.menu-mobile {display: none !important;}

.static-page h2,
.static-page h3 { margin-bottom: 32px; }

.static-page, .card.static-page {
    padding-left: 32px; padding-right: 32px;
    font-size: 1.8rem; 
}

.static-page table {
    width: 100%;
    table-layout: fixed;
    margin: 8px 0 16px;
}

.static-page table tr td,
.static-page table tr th {
    padding: 8px;
}

.static-page p { margin-top: 8px; margin-bottom: 16px }


.footer {
    position: fixed; left: 0; bottom: 0; z-index: 3; 
    width: 100%; height: 36px;
}

.footer-content { 
    display: block;
    position: relative;
    width: 100%; height: 100%;
    background: #cfcfcf;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
    font-size: 1.2rem; line-height: 40px;
    padding-left: 8px; padding-right: 8px;
}

.footer .logo { margin-top: 6px; margin-right: 16px; opacity: 0.1 }

.footer span, .footer a, .footer label { vertical-align: middle; font-weight: 300; }

.footer label { margin-right: 0; margin-top: 8px; }
.footer label svg { vertical-align: text-bottom; float: left; margin-right: 4px; }

.footer label small,
.footer label span { display: block; line-height: 1.0rem; white-space: nowrap }

.footer label a { position: relative; display: block; float: left; }
.footer label a .notification {
    padding: 0;
    background: #FF0000;
    color: #fff;
    position: absolute;
    height: 12px; width: 12px;
    top: -6px; right: 0; 
    text-align: center; font-weight: bold;
    line-height: normal;
    border-radius: 4px;
    font-size: 1.0rem;
}

/* ========= navbar ================= */
/* @import "App769.css"; */

@media (max-width: 769px) {
    .main { display: block; padding-top: 0; position: relative }
    .main .content { display: block; padding: 0 8px 0; height: 100%;  }
    
    .hide-on-mobile { display: none !important; }

	.sub-header { height: 10px; }
	
    .navbar-toggle { 
		font-size: 2.4rem; border: none; 
		margin: 5px 0 0; padding: 10px;
		float: none;
	}

    .navbar-fixed-side {
        width: auto; border: none;
        margin-bottom: 0;
	}
	
	.navbar-collapse.collapse {
		display: none !important;
		width: 100%;
        padding: 0;
        /*border-top: 1px solid #e7e7e7*/
    }
	
	.navbar > .container .navbar-brand {
		display: none
	}

	.nav > li > a { padding-left: 5px }
	.nav > li > a > * { display: inline-block; vertical-align: bottom; margin-right: 5px; }

    .container > .navbar-header { margin-left: 0; margin-right: 0; }	
    
    .page-heading { 
        font-size: 2.4rem; line-height: 2.8rem;
    }

    .overview { flex: 1; margin: 0; }
    .overview .topics-tree-container { padding: 0; flex: 1 }

    .votes-timeline-container { display: none; }

    .radial-bar-mobile { display: block; }
    .radial-bar-desktop { display: none; }

    .menu-mobile {
        display: block !important;
    }

    .navbar-mobile {
        height: 40px; 
        width: 100%;
        display: flex;
        box-sizing: border-box;
        flex: 1 1 auto;
        background: #e7e7e7eb;
        box-shadow: 0 0 50px #7773; 
        font-size: 1.5rem;
        padding: 0 20% 0 20%;
        bottom: 0;
        position: sticky; z-index: 999;
    }

}

.col-xs-12 { padding-right: 4px; padding-left: 4px; }

/* ANIMATIONS */
.animate,
.animate svg {
    transition: transform 0.3s linear;
}

.animate.animate-close {
    height: 0; overflow: hidden;
}

.animate.animate-open {
    height: auto; overflow: auto;
}

@media (min-width: 769px) {
    .hide-on-desktop {
        display: none;
    }

    .navbar-fixed-side {
        position: fixed;
        height: 100vh;
        width: inherit;
        border: none;
        z-index: 10;
    }
  
    .navbar-fixed-side .container,
    .navbar-fixed-side .container-fluid {
        width: auto;
        padding-right: 0;
        height: 60px;
    }

    .navbar-fixed-side .navbar-header {
        float: none;
    }

    .navbar-fixed-side .navbar-collapse {
        width: 100%;
        padding: 0;
        /*border-top: 1px solid #e7e7e7*/
    }

    .navbar-fixed-side .navbar-nav{
      width: inherit;
    }
    
    .navbar-fixed-side .navbar-nav > li {
        float: none; padding-left: 33px;
        font-size: 18px;
    }

    .navbar-fixed-side .navbar-nav > li > a {
		display: block; float: left;
        font-size: 2.4rem; color: #999;
        white-space: nowrap;
        transition: all 0.3s ease 0.3s;        
    }

    .navbar-fixed-side .navbar-nav > li > a span {
        margin-left: 8px;
        max-width: 0; display: inline-block; 
        overflow: hidden;
        transition: all 0.3s ease 0.3s;
    }
    
    .navbar-fixed-side .navbar-nav > li > a.active{
        color: #000;
    }

    .navbar-fixed-side .navbar-nav > li > a:hover svg {
        color: #000;        
    }        

    .navbar-fixed-side .navbar-nav > li > a:hover {
        background-color: #fff;
        transition: all 0.3s ease 1.2s;        
    }

    .navbar-fixed-side .navbar-nav > li > a:hover span {
        max-width: 300%;
        transition: all 0.3s ease 1.2s;
    }

  /* On using dropdown menu (To right shift popuped) */
    .navbar-fixed-side .navbar-nav .dropdown-menu {
        position: absolute;
        left: 100%;
        z-index: 1000;
        float: none;
        width: auto;
        margin-top: 0;
        border: 0;
        box-shadow: none;
        border-bottom: 1px solid #333333;
        background-color: #222222;
    }

      .navbar-fixed-side .navbar-nav .dropdown-menu>li>a,
      .navbar-fixed-side .navbar-nav .dropdown-menu .dropdown-header {
          padding: 5px 15px 5px 25px;
          z-index: 1000;
      }

      .navbar-fixed-side .navbar-nav .dropdown-menu>li>a {
          line-height: 20px;
          color: #777
      }

      .navbar-fixed-side .navbar-nav .dropdown-menu>li>a:hover,
      .navbar-fixed-side .navbar-nav .dropdown-menu>li>a:focus {
        background-image: none
     }

     .navbar-fixed-side .navbar-nav .dropdown-menu>.active>a {
        /*background-color: #e7e7e7;*/
        color: #555
     }

     .navbar-fixed-side .navbar-nav>li>a:hover,
      .navbar-fixed-side .navbar-nav>li>a:focus,
      .navbar-fixed-side .navbar-nav .dropdown-menu>li>a:hover,
      .navbar-fixed-side .navbar-nav .dropdown-menu>li>a:focus {
          color: #000;
      }

      .navbar-fixed-side .dropdown>.dropdown-toggle,
      .navbar-fixed-side .dropdown-menu>.dropdown-header {
        border-bottom: none;
    }

    .navbar-fixed-side .navbar-nav>.open>a,
    .navbar-fixed-side .navbar-nav>.open>a:focus,
    .navbar-fixed-side .navbar-nav>.open>a:hover {
		color: #000;
    }
}

@media (min-width: 769px) and (max-width: 1050px) {
    .main .content {
        height: calc(100% - 120px); 
    }
}

.ml8 {
    margin-left: 8px;
}

.invisible {
    visibility: hidden;
}

.form-group label.holler-toggle {
    margin-right: 4px;
    vertical-align: middle;
}
.form-group label.holler-toggle + .control-label {
    margin-bottom: 0;
}
.form-group label.holler-toggle + .control-label + .sub-label {
    margin-left: 30px;
}
