.presentation-mode-page .topic-preview {
	padding-bottom: 1.5vh;
	text-align: center;
}

.presentation-mode-page .votes-frame-container {
	display: flex;
	justify-content: center;
	overflow: auto;
	padding-bottom: 1.5vh;
}

.presentation-mode-page .votes-frame {
	min-width: 30vw;
	margin-left: 3vw;
	margin-right: 3vw;
	margin-bottom: 1.5vh;
	display: inline-grid;
	grid-template-columns: repeat(10, [col-start] 1fr);
	align-self: center;
}

.presentation-mode-page .vote-qr {
	display: inline-block;
	align-self: center;
	width: 30vw;
	max-height: 30vw;
}

.presentation-mode-page .vote-qr .qr-preview {
	width: 100%;
	height: 100%;
}

@media (max-width: 769px) {
	.presentation-mode-page .votes-frame-container {
		flex-direction: column;
		overflow: visible;
	}
	.presentation-mode-page .votes-frame {
		min-width: 35vh;
	}
	.presentation-mode-page .vote-qr {
		max-height: 35vh;
		width: 35vh;
	}
}

.presentation-mode-page .vote-box {
	border: 1px solid #f3f3f3;
}

.presentation-mode-page .votes-text {
	height: 3vh;
	text-align: center;
	padding-bottom: 1.5vh;
	overflow: hidden;
}

.presentation-mode-page .votes-text-entry {
	display: inline-block;
	margin-left: 0.3vw;
	max-width: 15vw;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.presentation-mode-page .vote-location {
	font-style: italic;
}

@keyframes act-vote-given {
	0% {
		transform: scale(1);
		visibility: visible;
	}
	1.75% {
		transform: scale(1.5);
	}
	2.5% {
		opacity: 0.3;
		transform: scale(1);
	}
	50% {
		opacity: 0.15;
	}
	100% {
		opacity: 0.15;
		visibility: hidden;
	}
}
