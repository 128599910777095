.confirm-on-close-holder {
    display: inline;
}

.confirm-on-close-overlay{ 
	background: rgba(255,255, 255, 0.9);
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
    border-radius: 2px;
    position: absolute;
	z-index: 10003;
	display: flex;
    justify-content: center;
    align-items: center;
}

.message-confirm-container { text-align: center; }
.message-confirm-container .btn-toolbar { display: inline-block; }

.full-size .message-confirm-container {
    width: 300px; height: 100px;
    top: 50%; left: 50%;
    margin-top: -150px; 
}