.topic-links-modal-heading {
	background: #5cc65c;
	color: #fff; font-size: 1.6rem; text-transform: uppercase;
	padding: 8px 0 32px;
}

.topic-links-modal .check-circle { 
	width: 68px; height: 68px; 
	border-radius: 50%;
	background: #5cc65c;
    padding: 6px 4px;
    margin-top: -31px; margin-left: auto; margin-right: auto;
}

.topic-links-modal-heading h2 { font-weight: bold; margin-top: 8px }
.topic-links-modal-heading p { font-weight: bold }

.topic-links-modal-content { margin: 24px 0 24px; }
.topic-links-modal-content a.topic-url { font-size: 2.4rem; color: #3184d1; }

.topic-links-modal-content .card { width: 300px; padding: 32px; margin: 8px auto 48px; }
.topic-links-modal-content .card.html-snippet-card { width: auto; padding: 6px; margin: 12px; }

.topic-links-modal-content a.download:first-child { margin: 2px 0 0 8px; }

.topic-links-modal-content .embeddable-html-snippet { margin: 35px auto 10px auto; }

.topic-links-modal-content .embeddable-html-snippet .holler-live-vote-img { margin-top: 30px; }
.topic-links-modal-content .embeddable-html-snippet .html-snippet-salutation { text-align: left; }

.topic-links-modal-content .embeddable-html-snippet .html-snippet-preview-box { margin-top: 8px; }
.topic-links-modal-content .embeddable-html-snippet .html-snippet-preview { height: 140px; overflow: auto; }

.topic-links-modal-content a.copy-to-clipboard { 
	font-size: 1.2rem; color: #bbb;
	margin-left: 8px; line-height: 2.4rem;
	display: inline-block;
}

.topic-links-modal-content a.copy-to-clipboard svg {vertical-align: inherit; line-height: baseline; }