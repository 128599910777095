.after-vote-config .copy-link-wrapper-disabled-container,
.after-vote-config .share-on-facebook-disabled-container {
    margin-bottom: 4px;
}

.after-vote-config .copy-link-disabled-sublabel,
.after-vote-config .share-on-facebook-disabled-sublabel {
	font-size: 13px;
	font-weight: 300;
}

