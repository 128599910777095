.ordered-topic-list .topic-id-cell {
	width: 455px;
}

.ordered-topic-list .topic-id-cell-content {
	display: flex;
	justify-content: space-between;
	
}
.ordered-topic-list .private-mark {
	display: flex;
	align-items: center;
}
.ordered-topic-list .private-mark > svg {
	margin-right: 5px;
}