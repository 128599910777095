.users-page { height: 100%; }

.users-page .filters-container { width: calc(100% - 120px); display: inline-block }

.users-page .list-container { height: calc(100% - 78px) }

.users-page .grid-container { height: calc(100% - 52px) }

.user-management-grid-container, .users-grid { height: 100%; }

.users-grid .react-bs-container-body { overflow-x: auto; }
.users-grid .react-bs-table-container { height: calc(100% - 140px) }
.users-grid .react-bs-table table { table-layout: auto; }

.react-bs-container-body { overflow-x: hidden; }
.react-bs-container-body table tr td:last-child { padding-right: 18px; }
.react-bs-container-body table tr.active td:first-child  { border-left: 8px solid #337ab7; }
.react-bs-container-body table tr.checked td:first-child { border-left: 8px solid #ccc; }

.user-edit-form { padding: 8px 8px 0; }

.online-only-filter {
    margin-bottom: 0;
    height: 25px;
}