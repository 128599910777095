.switch-field input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
}

.switch-field label {
    float: left;
    display: inline-block;
    width: auto;
    background-color: #f6f6f6;
    color: #acacac;
    font-size: 12px;
    font-weight: normal;
    text-align: center;
    text-shadow: none;
    padding: 6px 14px;
    border-top: 1px solid #acacac;
    border-bottom: 1px solid #acacac;
    border-right: 1px solid #acacac;
    text-transform: uppercase;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.switch-field label:hover {
    cursor: pointer;
}

.switch-field input:checked + label {
    background-color: #3899c6;
    color: white;
}

.switch-field span:first-of-type label {
    border-radius: 4px 0 0 4px;
    border-left: 1px solid #acacac;
}

.switch-field span:last-of-type label{
    border-radius: 0 4px 4px 0;
}
