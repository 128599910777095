.rating-badge { 
	display: inline-block; 
	padding: 8px 0; border-radius: 100%; position: relative; z-index: 0; 
	font-weight: normal; font-size: 1.6rem; color: #fff; text-align: center;
	width: 3.8rem; height: 3.8rem;
}

.rating-badge small { color: #fff; } 

.rating-relative-badge { 
	font-size: 1.2em;
    position: relative;
    z-index: 1;
    top: -0.8em;
    width: 3.2rem;
    height: 3.2rem;
    line-height: 1.6rem;
}

.rating-0 { background-color: #bbb;}
.rating-0-text { color: #bbb }

.rating-1 { background-color: #cc3a3a; }
.rating-1-text { color: #cc3a3a; }

.rating-2 { background-color: #cc3a3a; }
.rating-2-text { color: #cc3a3a; }

.rating-3 { background-color: #e35f77; }
.rating-3-text { color: #e35f77; }

.rating-4 { background-color: #e35f77; }
.rating-4-text { color: #e35f77; }

.rating-5 { background-color: #5fa0e3; }
.rating-5-text { color: #5fa0e3; }

.rating-6 { background-color: #5fa0e3; }
.rating-6-text { color: #5fa0e3; }

.rating-7 { background-color: #32de5e; }
.rating-7-text { color: #32de5e; }

.rating-8 { background-color: #32de5e; }
.rating-8-text { color: #32de5e; }

.rating-9 { background-color: #4af86b; }
.rating-9-text { color: #32de5e; }

.rating-10 { background-color: #4af86b; }
.rating-10-text { color: #32de5e; }