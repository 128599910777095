.card.dataset-filters-container {
    margin: 24px 0;
    padding: 0;
}

.dataset-filters-container .common-filters-bar {
    height: 4.8rem;
    line-height: 4.8rem;
    margin-left: 8px;
    display: flex;
}

.dataset-filters-container .common-filters-bar > label {
    font-weight: normal;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1 1;
}

.dataset-filters-container .common-filters-bar > label strong {
    color: #333;
}

.dataset-filters-container .common-filters-bar .actions {
    font-weight: bold;
    vertical-align: middle;
    height: 100%;
    width: 102px;
}
.dataset-filters-container .common-filters-bar .actions .dataset-toggle {
    margin-left: 4px;
    display: inline-block;
}
.dataset-filters-container .common-filters-bar .actions .btn {
    height: 100%;
    border-left: 1px solid #e1e1e1;
    margin-left: 8px;
    padding-left: 4px;
    padding-right: 4px;
}

.dataset-filters-container .dataset-filters {
    padding: 0 8px 8px;
}

.dataset-filters hr {
    margin: 2px;
    border-top: 1px solid #e1e1e1;
    border-bottom: 1px solid #f5f5f5;
}

.dataset-filters .dataset-filters.text-muted {
    opacity: 0.7;
}
.dataset-filters h4 {
    margin-left: 0;
    margin-top: 4px;
    margin-bottom: 0;
    padding-top: 8px;
    text-align: right;
}
.dataset-filters h4 .dataset-toggle {
    margin-right: 4px;
}

.dataset-filters h5 {
    margin: 8px 4px 8px;
    display: inline-block;
    width: 120px;
}
.dataset-filters h5 .btn {
    padding: 2px 4px 0;
}
.dataset-filters h5 .btn svg {
    margin-right: 0;
}

.dataset-filters .filter-options-group {
    padding: 0;
    width: calc(100% - 130px);
    display: inline-block;
}

.dataset-filters .filter-option-toggle {
    padding-left: 4px;
    padding-right: 4px;
    text-transform: uppercase;
    line-height: 3.6rem;
    display: inline-block;
    width: 16%;
    min-width: 130px;
    white-space: nowrap;
}

.dataset-filters .filter-option-toggle:last-child {
    border: none;
}

.dataset-filters .filter-option-toggle .dataset-toggle {
    margin-top: 10px;
    margin-right: 4px;
}

.dataset-filters .btn-toolbar {
    display: block;
    margin: 16px 0 8px;
}
.dataset-filters .btn-toolbar .btn {
    float: none;
}

@media (max-width: 769px) {
    .dataset-filters-container .common-filters-bar > label .static-text {
        display: none;
    }
}
