.content-with-stats { display: flex; flex-flow: row wrap; }
.content-with-stats .stats-header { width: 100%; display: flex; }

.lifetimestats-container { display: flex; align-items: center; margin-left: auto; }
.lifetimestats-container button { margin-left: 1vw; }

.topic-details-header-right-section { display: flex; flex-wrap: wrap; }

.content-with-stats .stats-container { flex: 1 1; max-width: 320px; width: 320px; }
.content-with-stats .flex { flex-wrap: wrap; }

.content-with-stats .stats-col.always-horizontal { display: flex; flex-direction: row; }
.content-with-stats .stats-col { display: flex; flex-direction: column; flex: 1 1 }

.topic-details-header-container { display: flex; flex-wrap: wrap; }
.topic-details-header-container .buttons-n-stats-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: auto;
}

.buttons-n-stats-container .buttons-container {
    display: flex;
    justify-content: flex-end;
}

.stats-row { display: flex; width: 100%; }
.stats-row .stats-card { flex: 1 1; width: 130px; box-sizing: border-box; overflow: hidden; margin: 3px; }

.content-with-stats .stats-container .direct-avg-rating {
    text-align: center; margin: 0 auto;
}

.content-with-stats .stats-container .direct-avg-rating .related-rating {
    margin-top: -12px;
}

.content-with-stats .stats-container .direct-avg-rating label { font-size: 1.2rem; text-transform: uppercase; font-weight: normal; }

.content-with-stats .content-container { flex: 1 }

.topic-details .entity-name a.topic-details-active-view { display: none; }

.latest-votes-container { flex: 1; max-width: 260px; width: 260px; }

@media (min-width: 1367px) {
    .content-with-stats .stats-container > .flex { display: block; }
    .content-with-stats .flex .stats-col.small { max-width: auto; flex-direction: row; }
}

@media (max-width: 1367px) and (min-width: 769px) {
    .content-with-stats .stats-container { width: 100%; min-width: 100%; max-width: 100%; }
    .content-with-stats .flex { flex-wrap: nowrap; flex-direction: row; max-width: 100%;}
    .content-with-stats .flex .stats-col.small { max-width: 140px; flex-direction: column; }
        
    .stats-row .stats-card { 
        min-width: 0; min-height: 104px; 
        
    }
    
    .stats-card h4 { width: 100%; }
}

@media (max-width: 769px) {
    .content-with-stats { flex-direction: column; min-height: calc(100% - 158px); }

    .content-with-stats .content-container { width: 100%; }
    .content-with-stats .overview-stats-switch { height: 36px; flex: none; display: block; }
    
    .content-with-stats .stats-container { width: 100%; min-width: 100%; max-width: 100%; }
    .content-with-stats .stats-container > .flex { display: block; }

    .content-with-stats .flex .stats-col.small { max-width: 140px; flex-direction: column; }
    .content-with-stats .flex .stats-col { width: 100%; flex-wrap: nowrap; flex-direction: row; }
}