.cookie-message {
	position: fixed;
	bottom: 32px;
	width: 100%; left: 0;
	padding-left: 64px; padding-right: 64px;
}

@media (max-width: 769px) {
	.cookie-message {
		position: unset;
		overflow: hidden; text-overflow: ellipsis;
		bottom: auto; width:  auto;
		margin-left: 8px; margin-right: 8px; margin-top: 0;
		padding-bottom: 36px; padding-left: 0; padding-right: 0;
	}
}