.mobile-navigation { width: 120px; }

@media (max-width: 769px) {
    .main .mobile-navigation { width: auto; display: block; height: 0; margin-bottom: -7px }

	#menuToggle {
		display: block; position: relative;
		z-index: 1; margin: 12px 8px 0;
		-webkit-user-select: none;
		user-select: none;
	  }
		
	/*
	* Just a quick hamburger
	*/
	#menuToggle span.hamburger {
		display: block;
		width: 2.4rem; height: 4px; margin: 5px;
		z-index: 1; position: relative;
		
		background: #cdcdcd; border-radius: 3px;
		
		transform-origin: 4px 0px;
		transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
					background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
					opacity 0.55s ease;
	}
	
	#menuToggle span.hamburger:first-child { transform-origin: 0% 0%; }
	
	#menuToggle span.hamburger:nth-last-child(2) { transform-origin: 0% 100%; }
	
	/* 
	* Transform all the slices of hamburger
	* into a crossmark.
	*/
	#menuToggle input:checked ~ span.hamburger { 
		opacity: 1;
		transform: rotate(45deg) translate(-9px, -12px);
		background: #232323;
	}
	
	/*
	* But let's hide the middle one.
	*/
	#menuToggle input:checked ~ span.hamburger:nth-last-child(3) { opacity: 0; transform: rotate(0deg) scale(0.2, 0.2); }
	
	/*
	* Ohyeah and the last one should go the other direction
	*/
	#menuToggle input:checked ~ span.hamburger:nth-last-child(2) { 
		transform: rotate(-45deg) translate(-2px, 8px); 
	}

	.mobile-navigation ul {
		position: absolute; top: 0;
		width: 200px; padding: 0; margin: 0;
		
		background: #ebebeb; 
		list-style-type: none;
		-webkit-font-smoothing: antialiased;
		/* to stop flickering of text in safari */
		
		transform-origin: 0% 0%;
		transform: translate(-100%, 0);
		
		transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
	  }
	  
	.mobile-navigation ul  { position: absolute; margin-top: 0.4rem; }

	.mobile-navigation ul  > li > a,
	.mobile-navigation ul  > li > button,
	.mobile-navigation ul  > li > .dropdown-toggle { 
		color: #666; line-height: 2.4rem; text-transform: uppercase;
		padding: 16px 8px; font-size: 1.8rem;
	}

	.mobile-navigation ul  > li > a svg { vertical-align: middle }

	.mobile-navigation ul  > li > a.active { 
		background: #ddd; color: #000; font-weight: bold;
	}
}