.entity-details-form .zero-margin {
    margin: 0;
}

.entity-details-form .entity-logo-upload .control-label {
    position: relative;
    bottom: -22px;
}

.entity-details-form .entity-logo-upload .imageWrapper {
    min-width: auto;
}

.entity-details-form .entity-logo-container.fit-desktop-flex-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1 1;
}

.entity-details-form .entity-logo-container .entity-logo-upload.fit-desktop-flex-item {
    flex-basis: 33.3%;
}

.entity-details-form .entity-logo-container .entity-main-data.fit-desktop-flex-item {
    flex-basis: 66.6%;
}