.topic-builder-container {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
}

.steps-container {
    flex: 1;
}

.topic-builder-container p.overall-server-error {
    font-weight: bold;
    padding: 16px;
    margin: 8px 4px;
}

.progressBar-container {
    width: 300px;
    position: sticky;
    top: 0;
}

.sub-progress .card {
    border-radius: 0 0 4px 4px;
    margin-bottom: -10px;
    margin-left: -8px;
    width: calc(100% + 16px);
}

.meta-info {
    background: #dcdcdc;
}

.update-status-container {
    height: 80px;
    background: #39b54a;
}

.form-control.has-error {
    border-color: #d15e42;
    color: #d15e42;
}

input.dotted,
.form-control.dotted[disabled],
fieldset[disabled] .form-control.dotted {
    border-style: dashed;
    background-color: #fff;
    cursor: default;
}

form .card {
    margin-bottom: 24px;
}

form .form-group {
    margin: 8px 8px 24px;
    clear: both;
}

form .group-panel {
    margin-top: 16px;
}

.form-errors {
    height: 20px;
    overflow: hidden;
}

.form-errors span {
    display: block;
    margin: 4px;
    font-size: 12px;
    text-transform: uppercase;
    color: #d15e42;
}

.form-errors {
    height: 20px;
    overflow: hidden;
}

.form-warnings {
    height: 20px;
    overflow: hidden;
}

.form-warnings span {
    display: block;
    margin: 4px;
    font-size: 12px;
    text-transform: uppercase;
    color: #f5c117;
}

.wizard-controls {
    margin-top: 24px;
}

/* form .row-fluid div[class^="col-md"] { padding: 0; } */
form .row-fluid div[class^='col-md']:first-of-type {
    padding-left: 0;
}
form .row-fluid div[class^='col-md']:last-of-type {
    padding-right: 0;
}

.form-group label {
    text-transform: uppercase;
    margin-bottom: 4px;
}

.form-group.horizontal label {
    padding-top: 6px;
    text-align: right;
}

.form-group.horizontal div[class*='col-md'],
.form-group.horizontal label[class*='col-md'] {
    padding-right: 0;
    text-align: right;
}
.form-group.horizontal div[class*='col-md'] span.control-label {
    text-align: left;
    display: block;
}

.form-group .sub-label {
    text-transform: uppercase;
    font-size: 1.1rem;
    color: #aaa;
    display: block;
}

.ordered-form-fields .ordered-form-fields-item {
    margin-bottom: 8px;
}

.ordered-form-fields .ordered-form-fields-item .form-control,
.ordered-form-fields .ordered-form-fields-item .form-control-container {
    display: inline-block;
    width: calc(100% - 3.4rem);
}

.with-action > .form-control-container {
    display: inline-block;
}

.with-action > .form-control,
.with-action > .form-control-container,
.ordered-form-fields .ordered-form-fields-item.with-action > .form-control-container {
    width: calc(100% - 6.8rem);
}

.with-action .action {
    padding-left: 4px;
    display: inline-block;
    margin-top: 1.4rem;
    vertical-align: top;
}

.with-action .action .confirm-button-holder button {
    margin-top: -0.6rem;
}

.with-action .action svg {
    vertical-align: middle;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
}

.with-action .action:hover svg {
    transform: scale(1.2, 1.2);
}

.ordered-form-fields .ordered-form-fields-item .number {
    vertical-align: top;
    display: inline-block;
    margin-right: 1rem;
    margin-top: 1.4rem;
    width: 2.4rem;
    height: 2.4rem;
    border: 1px solid #888;
    border-radius: 1.8rem;
    line-height: 2.4rem;
    text-align: center;
}

form .config-alerts-container .alert-item-interval-custom.form-group {
    margin: 2px 2px 10px 2px;
}

.config-alerts-inner-container.form-group {
    padding: 8px;
    margin: 0;
}

.config-alerts-inner-container .card {
    margin-top: 0;
    margin-bottom: 2px;
}

.config-alerts-inner-container .alert-item-container .alert-item-interval-buttons {
    margin: 0;
    width: 66.6%;
}

.config-alerts-inner-container .alert-item-container .alert-item-interval-custom {
    margin: 0;
    width: 33.3%;
}

.top-right-question-button {
    margin: 4px 10px 12px;
    position: relative;
    float: right;
}

.duplicate-question-button-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: flex-start;
}

.delete-greeting-button {
    background-color: transparent;
    position: absolute;
    border: none;
    padding: 5px;
}

.selector-disabled {
    background: transparent;
    border-style: dashed;
}

.confirm-greeting-message {
    width: calc(100% - 320px) !important;
    height: auto;
    display: inline-flex;
    position: absolute;
    left: 10px;
    margin-top: -13px;
}

.link-text-description {
    padding: 8px;
}
.link-text-description button {
    border-width: 0;
    padding: 0;
}
.link-preview-wrapper {
    display: flex;
    justify-content: center;
}
.link-preview {
    background-color: #fff;
    padding: 8px;
    margin-bottom: 8px;
    max-width: 1200px;
}
.link-preview-img {
    display: table-cell;
}
.link-preview-caption {
    display: table-caption;
}
.link-preview-caption > * {
    word-break: break-all;
}
.img-responsive {
    margin: auto;
}
.right-childs-margin-half-vw > :not(:last-child) {
    margin-right: 0.5vw;
}

.translations .translations-footer {
    position: relative;
}

.enforce-lang-toggle-container {
    margin-top: 10px;
}
.enforce-lang-toggle {
    vertical-align: top;
}

.changes-saved {
    margin-top: 4px;
}
.changes-saved svg {
    vertical-align: text-bottom;
}

.backend-error {
    height: auto;
}
.form-errors span,
.backend-error span {
    display: block;
    margin: 4px;
    font-size: 12px;
    text-transform: uppercase;
    color: #d15e42;
}

.animate-down:hover svg {
    margin-bottom: -4px;
}
.animate-up:hover svg {
    margin-top: -4px;
}

.animate-expand:hover svg {
    transform: scale(1.2);
}

.animate-collapse:hover svg {
    transform: scale(0.8);
}

.animate-shrink {
    transition-delay: 0.05s;
}
.animate-shrink-down {
    transform: translateY(calc(100% + 24px));
}
.animate-shrink-up {
    transform: translateY(calc(-100% - 24px));
}

@media (max-width: 482px) {
    .confirm-greeting-message {
        width: calc(100% - 20px) !important;
    }
}

.imageWrapper {
    min-height: 150px;
    height: 100%;
    width: 100%;
    min-width: 200px;
    border-width: 2px;
    border-color: #aeaead;
    border-style: dashed;
    border-radius: 5px;
    position: relative;
    background-color: #e1e1e1;
    text-align: center;
}

.imageWrapper img {
    max-width: 100%;
    max-height: 100%;
    display: inline-block;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0.6;
}

.imageWrapperError {
    border-color: #a94442;
}
