.topic-tree-scroll-container{ height: calc(100% - 201px) }
.topics-tree{ min-height: calc(100% - 8px); }

.level-0 { background-color: #f8f8f8; }
.level-1 { background-color: #eee; margin-left: 8px; margin-right: 8px; }
.level-2 { background-color: #ddd; margin-left: 16px; margin-right: 16px; }
.level-3 { background-color: #d1d1d1; margin-left: 20px; margin-right: 20px; }
.level-4 { background-color: #ccc; margin-left: 24px; margin-right: 24px; }
.level-5 { background-color: #c1c1c1; margin-left: 28px; margin-right: 28px; }
.level-6 { background-color: #c1c1c1; margin-left: 32px; margin-right: 32px; }
.level-7 { background-color: #c1c1c1; margin-left: 36px; margin-right: 36px; }
.level-8 { background-color: #c1c1c1; margin-left: 40px; margin-right: 40px; }

.common-filters-bar {
	color: #888;
	font-size: 1.6rem; text-transform: uppercase; font-weight: 300;
	margin-bottom: 8px; margin-top: 8px;
}

.common-filters-bar .common-options-selector > *,
.common-filters-bar .common-options-selector .option {
	font-size: 1.6rem;
	font-weight: 700;
}

.search-topics { margin-bottom: 32px; width: 100%; }

.form-inline .search-topics span.input-group-addon { 
	border-right: none; border-left: none; border-top: none; background: none; 
	padding: 8px;
	width: 48px;
}

.search-topics input.form-control { 
	padding: 8px; 
	border-right: none; border-left: none; border-top: none; box-shadow: none;
	font-size: 1.8rem; line-height: 2.4rem; text-transform: uppercase;  font-family: inherit;
}

.topic-tree-item { position: relative; z-index: 0; }
.topic-tree-item span.expand-collapse {
	cursor: pointer;
	height: 100%;
	padding: 10px 15px 0 15px;
	margin: 0;
	line-height: 3.4em;
}

.list-group { position: relative; z-index: 1; margin-bottom: 5px }

.list-group-item { 
	position: relative;
	z-index: 1;
	display: flex;
	padding: 0;
}

.topic-tree-item-link {
	padding-top: 10px;
	color: #333;
	line-height: 2.4em;
	font-size: 1.6rem;
	flex: 3;
}

.level-0 .topic-tree-item-link { font-size: 2.4rem; font-weight: 700; }
.level-1 .topic-tree-item-link { font-size: 1.8rem; font-weight: 500 }
.level-2 .topic-tree-item-link { font-size: 1.6rem; font-weight: 400; }

.topic-tree-item-link:hover { color: #337ab7 }
.topic-tree-item-link:hover { text-decoration: none; }
.topic-tree-item-link span { display: inline-block; line-height: 2.4rem; }
.topic-tree-item-link small { opacity: 0; font-size: 1.2rem; vertical-align: middle; }

.topic-tree-item-button {
	color: #777;
}

.topic-tree-item.stacked { z-index: -1; }
.topic-tree-item > .stackedOverlay { position: relative; z-index: 2; }

.list-group-item.stacked { 
	width: 100%; max-height: 57px;
	overflow: hidden;
	position: absolute; z-index: 0; 
	margin: -10px auto 0; 
	transform: scale(0.99, 0.95); 
	filter: blur(2px) brightness(0.9)
	/* background: #ddd; */
}

.list-group-item.level-1.stacked { 
	transform: scale(0.97, 0.95); 
}

.show-more-list-items {
	margin: 8px auto 0;
	display: block;
	width: 150px;
	z-index: 100;
}

.topic-tree-item > .list-group-item { padding-bottom: 0; border-radius: 0; border: none; margin-bottom: 1px; }
.topic-tree-item > .list-group-item:hover .topic-tree-item-link small { opacity: 1; }

.rating-bar {
	padding: 10px 15px 0 0;
	width: 215px;
	text-align: right;
	font-size: 1.2rem;
	font-weight: 300;
}
.rating { text-align: center; margin-left: 8px; display: inline-block; vertical-align: top;  }

.rating-relative-badge { 
    position: relative;
    z-index: 1;
    top: -0.8em;
}

.votes-volume-container { padding-top: 8px; vertical-align: top; }
.votes-volume-container span { line-height: 2.2rem }
.votes-volume-container small { margin-top: 8px; display: inline-block }

.list-group-item .mini-chart {
	background: rgba(255, 255, 255, 0.6);
	padding: 12px 8px 8px; 
	margin: 0; 
}

.list-group-item .mini-chart canvas { filter: blur(0.6px) opacity(50%); }
.list-group-item .mini-chart small { opacity: 0; }

.list-group-item:hover .mini-chart canvas { filter: blur(0.6px) opacity(100%); }
.list-group-item:hover .mini-chart small { opacity: 0.6; }

.filterInput {
	border-left: none; border-right: none; border-top: none;
	margin-left: 4px; margin-right: 4px; padding: 8px;
	background-color: #ebfdfb;
}

.entity-name { 
	font-weight: 900; font-size: 3.6rem;
    text-transform: uppercase;
    margin: 0 12px 16px; line-height: 60px; min-height: 60px;
}

.entity-name .btn-toolbar { display: inline-block; }

/* TOPIC DETAILS PAGE */
.subpages-nav {
	box-shadow: 0 1px 2px 1px rgba(0, 0, 0, .10);
	margin: 8px 4px -8px;
	height: 5.6rem;
	display: flex;
}

.subpages-nav .tab {
	flex: 1 1;
	height: 100%; 
	line-height: 5.6rem; text-align: center; font-size: 1.6rem; text-transform: uppercase;
	color: #777;
	border-radius: 2px;
	background-color: #ddd;
	box-sizing: border-box;
	display: inline-block;
	text-decoration: none;
	overflow: hidden; text-overflow: ellipsis;
}

.subpages-nav .tab svg { vertical-align: text-top }

.subpages-nav .tab:hover { background-color: #eee; color: #333; }
.subpages-nav .tab.active { background-color: #f5f5f5; color: #333; }

.btn-non-free-account { position: relative; margin-right: 8px }

/* TOPIC DETAILS */
.votes-filters { 
	margin-left: 3px; margin-right: 3px;
    border-bottom: 8px solid;
    border-top: 8px solid;
	box-shadow: 0 8px 24px;
}

.topic-details-page .stick-top { position: sticky; top: -24px; }
.topic-details-page .votes-filters-wrapper { z-index: 3; }

.votes-filters .card-content { display: block; padding: 4px 0 4px 4px }
.votes-filters .filter-votes-title-text { max-width: 240px; display: inline-block; }

.votes-filters .filter-controls { 
	display: inline-flex !important;
	align-self: flex-start; 
}
.votes-filters .filter-controls .votes-range { min-width: 240px; display: inline-block; vertical-align: top; }
.votes-filters .filter-controls .votes-filters-details-container { flex: 1 1; }

.votes-filters .actions { width: 146px; float: right;  vertical-align: top; }

.votes-filters.votes-filters-expanded .filter-votes-title-text { min-width: calc(100% - 36px); }

.votes-filters.votes-filters-expanded .filter-controls { width: 100%; margin-top: 8px; margin-bottom: 8px; }
.votes-filters.votes-filters-expanded .filter-controls .votes-range { margin-left: 30px; }
.votes-filters.votes-filters-expanded .actions { width: 36px; float: none; display: inline-block; }

.votes-filters .filter-votes-title-text .text-label { cursor: pointer; }

.votes-filters .time-range-selector { flex: 1 1; white-space: nowrap; margin-right: 16px; }
.votes-filters .time-range-selector.custom-range { min-width: 460px }
/* .votes-filters .collapsed .time-range-selector { } */

.votes-filters .votes-filters-sources-textbox { background: transparent; height: 120px; overflow: auto; }
.votes-filters .votes-filters-sources-textbox.autocomplete-search-input { height: 40px; color: #555; }

.votes-filters .votes-filters-sources-textbox input { background: transparent; color: #ebebeb; }
.votes-filters .votes-filters-sources-textbox input::placeholder { color: #ebebeb; opacity: 1; }

.votes-filters .votes-filters-sources .text-label { margin-top: 4px; margin-right: 16px; text-align: right; display: block; }

.votes-filters .filter-toggles { flex: 1 1 }
.votes-filters .filter-toggles div[class^="col-md"] { padding-left: 0; padding-right: 0; }
.votes-filters .filter-toggles div[class^="col-md"].votes-filters-sources { padding-right: 32px; }

.votes-filters label.filter-toggles { cursor: pointer; }

.votes-filters .filter-toggles .btn-toolbar { position: absolute; bottom: 16px; right: 16px; }

/* QNA PAGE */
.questionnaire-container .stats-bar { display: flex; }
.questionnaire-container .stats-bar .progress { flex: 1 1; margin-bottom: 8px }
.questionnaire-container .stats-bar label { width: 108px; margin-left: 8px; }

.questionnaire-container .expand-card-footer {
	cursor: pointer;
	width: calc(100% + 17px); 
	margin-left: -8px; margin-bottom: -8px;
	text-align: center;
	background: linear-gradient(to bottom, rgba(0,0,0,0) 20%,rgba(0,0,0,0.1) 85%);
}

.question-details-item h4 {
    position: relative;
    z-index: 1;
}

.question-details-item .question-details-expand {
	opacity: 0.5; margin-top: 16px;
	border-color: rgba(0, 0, 0, 0);
	position: relative; z-index: 2;
}

.question-details-item:hover .question-details-expand {
	opacity: 1;
	border-color: #999
}

.topic-details-qna .questions-list-options-bar { 
	background-color: #e2e2e2; 
	padding: 8px;
	position: relative;
	border-radius: 2px; 
	box-shadow: 0 1px 2px 1px rgba(0, 0, 0, .10); 
	font-size: 1.5rem;
	font-weight: 300;
	margin: 10px 0 14px 0;
}

@media (min-width: 769px) {
	.topics-tree { margin: 0 4px; padding: 16px; }
	.votes-volume-container { display: inline-block; }

	.group-by-vote-container .group-vote-item { display: flex; }

	.group-by-vote-container .group-vote-item .vote-question-text { flex: 1 1; }
	.group-by-vote-container .group-vote-item .vote-answer-text { flex: 1 1; }
}

@media (max-width: 769px) {
	.list-group-item.level-1 { margin-left: 0; margin-right: 0; }
    .list-group-item.level-2 { margin-left: 2px; margin-right: 0; }
    .list-group-item.level-3 { margin-left: 4px; margin-right: 0; }
    .list-group-item.level-4 { margin-left: 6px; margin-right: 0; }
    .list-group-item.level-5 { margin-left: 8px; margin-right: 0; }
    .list-group-item.level-6 { margin-left: 10px; margin-right: 0; }
    .list-group-item.level-7 { margin-left: 12px; margin-right: 0; }
    .list-group-item.level-8 { margin-left: 14px; margin-right: 0; }


	.page-heading, .entity-name { margin: 5px 10px 10px 4.0rem; min-height: 3.0rem; }
	
	.page-heading .btn-toolbar,
	.entity-name .btn-toolbar { line-height: 3.6rem; }

	.list-group-item .mini-chart { display: none; }
	
    .topic-tree-item-link { line-height: 0; margin-top: 4px; width: calc(100% - 70px); vertical-align: super; overflow: hidden; text-overflow: ellipsis; }
    .topic-tree-item-link span { vertical-align: bottom; }
	.topic-tree-item span.expand-collapse { vertical-align: bottom; line-height: 3rem; }
	
	.subpages-nav { height: 4.2rem; }
	.subpages-nav .tab { font-size: 1.4rem; line-height: 4.2rem; }

	.rating-bar { width: 50px; }

	/* Votes filters */
	.votes-filters.sticky { 
		top: 0;
	    margin-top: 0;
		border-top-width: 0;
		margin-left: 0;
	}

	.votes-filters .time-range-selector.custom-selected { min-width: 100% }
	.votes-filters .filter-controls { display: inline-block !important; }
	.votes-filters.votes-filters-expanded .filter-controls { padding-left: 30px }
	.votes-filters .filter-controls .votes-filters-details-container { flex: none; margin-top: 8px; }
	.votes-filters.votes-filters-expanded .filter-controls .votes-range { margin-left: 0; }
	.votes-filters .votes-filters-sources .text-label { text-align: left; }

	.votes-filters label.filter-toggles { cursor: pointer; }
	.votes-filters .actions { width: 32px; position: absolute; right: 0; top: 8px; margin-left: -32px; }

	.votes-filters .filter-toggles div[class^="col-md"].votes-filters-sources { margin-bottom: 8px; }

	.question-details-list > .col-md-12{
		padding-left: 0; padding-right: 0;
	}
	
	.topic-details-page .stick-top { position: sticky; top: -14px; }
}

@media (max-width: 576px) {
	.subpages-nav .tab .tab-text {
		display: none;
	}
}
